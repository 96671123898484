import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as rawContentActions from '@tabeeb/modules/shared/content/actions'
import * as contentStateSelectors from '@tabeeb/modules/shared/content/selectors'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import * as permissionsSelectors from '@tabeeb/modules/permissions/selectors'
import { SessionPermission } from '@tabeeb/enums'
import * as notificationActions from '../../notification/actions'
import * as actions from '../actions'
import * as sessionsPageActions from '../../sessionsPage/actions'

import Navigation from '../components/Navigation'

class NavigationContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      filter: '',
    }
  }

  handleClick = (isGeneral) => (event) => {
    event.preventDefault()

    const { actions, currentFolder, sessionsList, sessionsPageActions } = this.props
    if (sessionsList.length > 0) {
      sessionsPageActions.setSessionsList([])
    }

    isGeneral ? actions.getGeneralContentsToSwitchRequest() : actions.getFolderContentsToSwitchRequest(currentFolder.Id)

    this.setState({
      open: true,
      anchorEl: event.target,
    })
  }

  handleSessionClick = (content) => {
    this.setState({
      open: false,
      filter: '',
    })

    const link = content.FolderId
      ? `${window.location.origin}${window.location.pathname}/whiteboard/plugin?sessionId=${content.Id}&folderId=${content.FolderId}`
      : `${window.location.origin}${window.location.pathname}/whiteboard/plugin?sessionId=${content.Id}`
    window.open(link, '_blank')
  }

  handleRequestClose = () => {
    this.setState({
      open: false,
      filter: '',
    })

    this.props.sessionsPageActions.setSessionsList([])
  }

  handleTextChange = (event) => {
    const text = event.target.value.trim()
    this.setState({
      filter: text,
    })
  }

  handleRenameSession = (e) => {
    const { name, contentId, contentActions } = this.props

    const newName = e.target.value.trim()
    if (newName.length && newName !== name) {
      const contentNameParts = null
      contentActions.renameContentRequest({ contentId, description: newName, contentNameParts })
    }
  }

  handleKeyUpName = (e) => {
    e.which === 13 && e.target.blur()
  }

  validateMouseDown = () => {
    const {
      notificationActions: { onAddErrorNotification },
      useSessionNameConvention,
      actions: { renameSessionUsingConvention },
      canRenameSession,
    } = this.props

    if (!canRenameSession) {
      onAddErrorNotification({ message: 'Only Session Owner can edit session name' })
      return false
    }

    if (useSessionNameConvention) {
      renameSessionUsingConvention()
      return false
    }
    return true
  }

  validateBlur = (value) => {
    if (value.trim().length === 0) {
      this.props.notificationActions.onAddErrorNotification({ message: "Session name can't be empty." })
      return false
    }
    return true
  }

  getFilteredSessions() {
    const { sessionsList } = this.props
    return sessionsList.filter(
      (session) => session.Description.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1
    )
  }

  render() {
    const { name, currentFolder, isContentLoading, contentId, sessionsNavbarTitle, isConfigLoaded } = this.props
    const filteredSessions = this.getFilteredSessions()

    const props = {
      name,
      handleRenameSession: this.handleRenameSession,
      handleKeyUpName: this.handleKeyUpName,
      validateMouseDown: this.validateMouseDown,
      validateBlur: this.validateBlur,
      folderName: currentFolder.Id ? currentFolder.Name : '',
      handleSessionsClick: this.handleClick(true),
      handleFolderClick: this.handleClick(false),
      handleRequestClose: this.handleRequestClose,
      anchorEl: this.state.anchorEl,
      open: this.state.open,
      sessions: filteredSessions,
      handleTextChange: this.handleTextChange,
      handleSessionClick: this.handleSessionClick,
      isContentLoading,
      contentId,
      sessionsNavbarTitle,
    }

    return isConfigLoaded ? <Navigation {...props} /> : null
  }
}

NavigationContainer.propTypes = {
  name: PropTypes.string.isRequired,
  contentId: PropTypes.number,
  contentActions: PropTypes.shape({
    renameContentRequest: PropTypes.func.isRequired,
  }).isRequired,
  notificationActions: PropTypes.shape({
    onAddErrorNotification: PropTypes.func.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    getGeneralContentsToSwitchRequest: PropTypes.func.isRequired,
    getFolderContentsToSwitchRequest: PropTypes.func.isRequired,
    renameSessionUsingConvention: PropTypes.func.isRequired,
  }),
  sessionsPageActions: PropTypes.shape({
    setSessionsList: PropTypes.func.isRequired,
  }),
  currentFolder: PropTypes.object,
  sessionsList: PropTypes.array,
  isContentLoading: PropTypes.bool.isRequired,
  descriptionCreatedUsingConvention: PropTypes.bool,
  useSessionNameConvention: PropTypes.bool,
  sessionsNavbarTitle: PropTypes.string.isRequired,
  isConfigLoaded: PropTypes.bool.isRequired,
  canRenameSession: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const { name, descriptionCreatedUsingConvention } = state.contentState
  const { currentFolder, sessionsList } = state.sessionsPage
  const { isContentLoading } = state.topNavBar

  const subdomain = appConfigStateSelectors.getSubdomain(state)
  const useSessionNameConvention = appConfigStateSelectors.getUseSessionNameConvention(state)

  const isConfigLoaded = !!subdomain

  return {
    name,
    contentId: contentStateSelectors.getContentId(state),
    currentFolder,
    sessionsList,
    isContentLoading,
    descriptionCreatedUsingConvention,
    useSessionNameConvention,
    sessionsNavbarTitle: appConfigStateSelectors.getSessionTitle(state),
    isConfigLoaded,
    canRenameSession: permissionsSelectors.hasSessionPermission(state, SessionPermission.RenameSession),
  }
}

const mapDispatchToProps = (dispatch) => ({
  contentActions: bindActionCreators(rawContentActions, dispatch),
  notificationActions: bindActionCreators(notificationActions, dispatch),
  actions: bindActionCreators(actions, dispatch),
  sessionsPageActions: bindActionCreators(sessionsPageActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavigationContainer)
