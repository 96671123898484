import { createSelector } from 'reselect'
import { TenantSessionsMode } from '@tabeeb/enums'
import { deletedColumns } from '@tabeeb/modules/tenantSessionsPage/services/enums'
import {
  ACTUAL_CONTENTS_DEFAULT_DASHBOARD_ID,
  defaultDashboard,
  DELETED_CONTENTS_DEFAULT_DASHBOARD_ID,
} from '@tabeeb/modules/tenantSessionsPage/services/dashboards'
import {
  getIsTenantContentsCustomDashboardsEnabled,
  getSessionTitle,
  getShowAllSessionsOriginalTenantColumn,
} from '@tabeeb/modules/appConfigState/selectors'

export const getCurrentMode = (state) => state.tenantSessionsPage.currentMode

export const getLastUpdateTime = (state) => state.tenantSessionsPage.lastUpdateTime

export const getIsShowOriginalTenantColumn = getShowAllSessionsOriginalTenantColumn
export const getIsCustomDashboardsEnabled = getIsTenantContentsCustomDashboardsEnabled

export const getUserDashboards = (state) => state.tenantSessionsPage.dashboards.list
export const getSelectedDashboardId = (state) => state.tenantSessionsPage.dashboards.selectedDashboardId

export const getTableColumns = (state) => state.tenantSessionsPage.columns

export const getContentsList = (state) => state.tenantSessionsPage.tenantContentsList

export const getSelectedContentsList = createSelector([getContentsList], (contentsList, selectedContentsIds) => {
  return contentsList.filter((c) => selectedContentsIds.some((id) => id === c.ContentId))
})

export const getContentById = createSelector([getContentsList, (state, { id }) => id], (contentsList, contentId) => {
  return contentsList.find((c) => c.ContentId === contentId)
})

export const getColumnsList = createSelector(
  [getIsShowOriginalTenantColumn, getCurrentMode, getTableColumns],
  (isShowOriginalTenant, currentMode, actualColumns) => {
    let columns = currentMode === TenantSessionsMode.Actual ? actualColumns : deletedColumns

    if (!isShowOriginalTenant) {
      columns = columns.filter((col) => col.PropName !== 'SentFromTenant')
    }

    return columns
  }
)

export const getTableDashboardsList = createSelector(
  [getUserDashboards, getSessionTitle],
  (userDashboards, sessionsTitleInTenant) => {
    const defaultTitle = sessionsTitleInTenant.toLowerCase()
    const defaultDashboardId = userDashboards.find((v) => v.IsDefault)?.Id || ACTUAL_CONTENTS_DEFAULT_DASHBOARD_ID

    const actualSessionsDefaultDashboard = {
      Id: ACTUAL_CONTENTS_DEFAULT_DASHBOARD_ID,
      Name: `All ${defaultTitle} ${defaultDashboardId === ACTUAL_CONTENTS_DEFAULT_DASHBOARD_ID ? ' (default)' : ''}`,
      IsPredefined: true,
    }

    const deletedSessionsDefaultDashboard = {
      Id: DELETED_CONTENTS_DEFAULT_DASHBOARD_ID,
      Name: `Deleted ${defaultTitle}`,
      IsPredefined: true,
      IsDeletedMode: true,
    }

    return [actualSessionsDefaultDashboard, deletedSessionsDefaultDashboard, ...userDashboards]
  }
)

export const getCurrentDashboard = createSelector(
  [getSelectedDashboardId, getTableDashboardsList],
  (selectedDashboardId, dashboards) => {
    const currentDashboard = dashboards.find((v) => v.Id === selectedDashboardId)
    return currentDashboard || defaultDashboard
  }
)
