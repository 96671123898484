import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import FilterPanel from '../components/FilterPanel'
import * as activityActions from '../actions'
import { getActivityTypesList } from '../services/activityHelper'
import { UserRole } from '../../../Enums'
import { accountSelectors } from '../../account'

class FilterPanelContainer extends Component {
  getActivitiesList() {
    const activitiesList = getActivityTypesList()
    return activitiesList
  }

  handleSelectActivityTypeFilter = (event) => {
    const { actions } = this.props
    const { value } = event.target
    actions.setActivityTypeFilter(value)
  }

  handleSelectUserFilter = (event) => {
    const { actions } = this.props
    const { value } = event.target
    actions.setUserFilter(value)
  }

  filterUsers = () => {
    const { users, showMenuAI } = this.props
    if (showMenuAI) {
      return users
    }

    return users.filter((user) => user.role !== UserRole.AIUser)
  }

  render() {
    const { selectedUserId, activityType, displayPage, actions } = this.props

    const pageTabCurrentProps = {
      tabText: 'Current Selection',
      isSelected: displayPage === 'current',
      handleClick: () => actions.setPageFilter('current'),
    }

    const pageTabAllProps = {
      tabText: 'All Selections',
      isSelected: displayPage === 'all',
      handleClick: () => actions.setPageFilter('all'),
    }

    const usersFilterProps = {
      users: this.filterUsers(),
      selectedUserId,
      onSelectUserFilter: actions.setUserFilter,
      handleSelectUserFilter: this.handleSelectUserFilter,
    }

    const activityTypeFilterProps = {
      activityType,
      handleSelectActivityTypeFilter: this.handleSelectActivityTypeFilter,
    }

    const props = {
      pageTabCurrentProps,
      pageTabAllProps,
      usersFilterProps,
      activityTypeFilterProps,
    }

    return <FilterPanel {...props} />
  }
}

FilterPanelContainer.propTypes = {
  selectedUserId: PropTypes.any.isRequired,
  activityType: PropTypes.string.isRequired,
  displayPage: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  showMenuAI: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    setUserFilter: PropTypes.func.isRequired,
    setActivityTypeFilter: PropTypes.func.isRequired,
    setPageFilter: PropTypes.func.isRequired,
  }).isRequired,
  currentPageId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => {
  const {
    activities: { activitiesFilter },
    users,
    gallery,
  } = state

  return {
    selectedUserId: activitiesFilter.userId,
    activityType: activitiesFilter.activityType,
    displayPage: activitiesFilter.displayPage,
    users: users.users,
    currentPageId: gallery.galleryState.selectedGalleryItemId,
    currentUserId: accountSelectors.getCurrentUserId(state),
    showMenuAI: appConfigStateSelectors.getShowMenuAI(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(activityActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanelContainer)
