import { combineActions, handleActions } from 'redux-actions'

import _ from 'lodash'

import * as aiActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [combineActions(aiActions.getUniqueAiObjectsByIds.success, aiActions.getUniqueAiObjects.success)](
      state,
      { payload, response }
    ) {
      const uniqueAIObjects = response.data

      return _.uniqBy([...state, ...uniqueAIObjects], (o) => o.Id)
    },
    [aiActions.updateUniqueAiObjectSuccess](state, { payload, response }) {
      return state.map((object) => {
        if (object.Id === response.data.Id) {
          return response.data
        }

        return object
      })
    },
    [aiActions.addUniqueAiObjectConnection.success](state, { payload, response: { data: addedConnection } }) {
      return state.map((uniqueAIObject) => {
        if (uniqueAIObject.Id === addedConnection.Parent.Id) {
          return {
            ...uniqueAIObject,
            ChildConnections: [...uniqueAIObject.ChildConnections, addedConnection],
          }
        }

        if (uniqueAIObject.Id === addedConnection.Child.Id) {
          return {
            ...uniqueAIObject,
            ParentConnections: [...uniqueAIObject.ParentConnections, addedConnection],
          }
        }

        return uniqueAIObject
      })
    },
    [aiActions.deleteUniqueAiObjectConnection.success](state, { payload, response }) {
      const { uniqueAIObjectConnectionId: deletedConnectionId } = payload

      return state.map((uniqueAIObject) => {
        const newParentConnections = uniqueAIObject.ParentConnections.filter(
          (connection) => connection.Id !== deletedConnectionId
        )
        const newChildConnections = uniqueAIObject.ChildConnections.filter(
          (connection) => connection.Id !== deletedConnectionId
        )

        return {
          ...uniqueAIObject,
          ParentConnections: newParentConnections,
          ChildConnections: newChildConnections,
        }
      })
    },
    [aiActions.addUniqueAiObject.success](state, { payload, response }) {
      const uniqueAIObject = response.data

      return _.uniqBy([...state, uniqueAIObject], (o) => o.Id)
    },
    [aiActions.autofillPageUniqueAiObjects.success](state, { payload, response }) {
      const uniqueAIObjects = response.data

      return _.uniqBy([...uniqueAIObjects, ...state], (o) => o.Id)
    },
    [aiActions.resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
