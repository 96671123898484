import { useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Box, Divider } from '@mui/material'
import { Grid, withStyles, Paper, ListItem, List, ListItemIcon, Fade } from '@material-ui/core'
import { Info, DeleteOutlined } from '@mui/icons-material'

import { getMe } from '@tabeeb/modules/account/selectors'
import UserQualificationsGrid from '@tabeeb/modules/qualifications/components/UserQualificationsGrid'
import { useDialogState } from '@tabeeb/modules/shared/utils/hooks'
import { openPoliciesViewer } from '../../../policies/actions'

import DeleteAccountDialog from '../DeleteAccountDialog'
import MainSettings from '../mainSettings'
import NotificationSettings from '../NotificationSettings'
import Address from '../address'
import HealthStatisticsContainer from '../../../healthData/containers/HealthStatisticsContainer'
import { Tab, getTabs } from '../../helper'

import Avatar from '../avatar'
import CertificatesGrid from '../../../certificates/components/CertificatesGrid'

import styles from './styles'

const UserSettings = ({ classes, showTabs, showDeleteAccountButton }) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(getMe)

  const [selectedTab, setSelectedTab] = useState(Tab.ProfileDetails)

  const handleSelectTab = useCallback(
    (value) => () => {
      setSelectedTab(value)
    },
    []
  )

  const handleOpenPoliciesViewer = useCallback(() => {
    dispatch(openPoliciesViewer())
  }, [dispatch])

  const [deleteAccountDialogOpen, onDeleteAccountDialogOpen, onDeleteAccountDialogClose] = useDialogState()

  const tabs = useMemo(() => getTabs(showTabs), [showTabs])

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
      }}
    >
      <Paper className={classes.content}>
        <Grid container direction='row' spacing={2} wrap='nowrap'>
          <Grid item xs={4} md={5} lg={3}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <Avatar />
              </Grid>
              <Grid item>
                <List>
                  {tabs.map(
                    (item) =>
                      item.toDisplay && (
                        <>
                          {item.displayDividerBefore && (
                            <div style={{ padding: '4px' }}>
                              <Divider sx={{ height: 0 }} />
                            </div>
                          )}
                          <ListItem
                            key={item.id}
                            selected={selectedTab === item.id}
                            button
                            onClick={handleSelectTab(item.id)}
                            {...(item.url && {
                              component: 'a',
                              href: item.url,
                              target: '_blank',
                              onClick: () => {},
                            })}
                          >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            {item.label}
                          </ListItem>
                        </>
                      )
                  )}
                </List>
              </Grid>
              <Grid item>
                <Divider sx={{ height: 0 }} />
              </Grid>
              <Grid item>
                <List>
                  <ListItem button onClick={handleOpenPoliciesViewer}>
                    <ListItemIcon>
                      <Info />
                    </ListItemIcon>
                    View policies
                  </ListItem>
                  {showDeleteAccountButton && (
                    <ListItem button onClick={onDeleteAccountDialogOpen}>
                      <ListItemIcon>
                        <DeleteOutlined />
                      </ListItemIcon>
                      Delete profile
                    </ListItem>
                  )}
                </List>
              </Grid>
            </Grid>
          </Grid>

          <Fade in>
            <Grid item xs={8} md={7} lg={9}>
              {selectedTab === Tab.ProfileDetails && <MainSettings />}
              {selectedTab === Tab.Address && <Address />}

              {selectedTab === Tab.Notifications && <NotificationSettings />}
              {selectedTab === Tab.HealthData && <HealthStatisticsContainer />}
              {selectedTab === Tab.Certificates && (
                <CertificatesGrid user={currentUser} personal boxSx={{ minWidth: '900px' }} />
              )}
              {selectedTab === Tab.Qualifications && (
                <UserQualificationsGrid simpleView user={currentUser} dataGridSx={{ minWidth: '900px' }} />
              )}
            </Grid>
          </Fade>
        </Grid>
      </Paper>
      <DeleteAccountDialog open={deleteAccountDialogOpen} onClose={onDeleteAccountDialogClose} />
    </Box>
  )
}

UserSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  showTabs: PropTypes.shape({
    showHealthData: PropTypes.bool.isRequired,
    showCertificates: PropTypes.bool.isRequired,
    showQualifications: PropTypes.bool.isRequired,
  }).isRequired,
  showDeleteAccountButton: PropTypes.bool.isRequired,
}

export default withStyles(styles)(UserSettings)
