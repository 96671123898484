import { useEffect, useLayoutEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import UpgradeNotification from '@tabeeb/modules/maintenance/components/UpgradeNotification'

import Dialogs from '../Dialogs'
import Filters from '../Filters'
import Folders from '../Folders'
import Header from '../Header'
import Pagination from '../Pagination'
import PageSizeSelector from '../Pagination/PageSizeSelector'
import PageThemeProvider from '../PageThemeProvider'
import Sessions from '../Sessions'
import TrashBin from '../TrashBin'

import {
  getDeletedFolders,
  getFolders,
  loadSessionsList,
  openTrash,
  resetState,
  setCurrentFolderId,
  setFeatures,
  setFilters,
  setFolderActions,
  setPageMode,
  setSessionActions,
  setSortings,
} from '../../actions'
import { Features, Filter, FolderAction, PageMode, SessionAction, SortingColumn } from '../../enums'

import { getPaginationPageNumber, getPaginationPageSize } from '../../selectors/pagination'

import { Container, PageContainer } from './styles'

const SessionsPage = ({
  deleted,
  mode,
  trashBinHref,
  folder,
  features,
  filters,
  folderActions,
  sortings,
  sessionActions,
}) => {
  const containerRef = useRef()
  const dispatch = useDispatch()

  const pageNumber = useSelector(getPaginationPageNumber)
  const pageSize = useSelector(getPaginationPageSize)

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [pageNumber, pageSize])

  useLayoutEffect(() => {
    dispatch(setFeatures(features))
    dispatch(setFilters(filters))
    dispatch(setSortings(sortings))
    dispatch(setSessionActions(sessionActions))
    dispatch(setFolderActions(folderActions))

    if (deleted) {
      dispatch(openTrash())
    }

    if (folder) {
      dispatch(setCurrentFolderId(folder))
    }

    if (sessionActions.includes(SessionAction.Move) || features.includes(Features.Folders)) {
      if (deleted) {
        dispatch(getDeletedFolders.request())
      } else {
        dispatch(getFolders.request())
      }
    }

    dispatch(setPageMode(mode))

    dispatch(loadSessionsList())

    return () => {
      dispatch(resetState())
    }
  }, [deleted, dispatch, features, filters, folder, folderActions, mode, sessionActions, sortings])

  return (
    <PageThemeProvider>
      <PageContainer>
        <Container ref={containerRef}>
          <UpgradeNotification sx={{ justifyContent: 'center', mt: 2 }} />

          {/* Filters */}
          <Filters />

          {/* Header */}
          <Header />

          {/* Folders list */}
          <Folders />

          {/* Sessions list */}
          <Sessions />

          {/* Footer */}
          <Box display='flex' alignItems='center' pb={2}>
            <Box display='flex' justifyContent='flex-start' flexGrow={1} maxWidth={200}>
              <TrashBin href={trashBinHref} />
            </Box>

            <Box display='flex' justifyContent='center' alignItems='center' flexGrow={1}>
              <Pagination />
            </Box>

            <Box display='flex' justifyContent='flex-end' flexGrow={1} maxWidth={200}>
              <PageSizeSelector />
            </Box>
          </Box>

          {/* Page dialogs */}
          <Dialogs />
        </Container>
      </PageContainer>
    </PageThemeProvider>
  )
}

SessionsPage.defaultProps = {
  deleted: false,
  mode: PageMode.MySessions,
  features: [],
  filters: [],
  sortings: [SortingColumn.Date, SortingColumn.LastActivity, SortingColumn.Name, SortingColumn.Owner],
  folderActions: [],
  sessionActions: [],
}

SessionsPage.propTypes = {
  deleted: PropTypes.bool,
  trashBinHref: PropTypes.string,
  mode: PropTypes.oneOf(Object.values(PageMode)),
  sortings: PropTypes.arrayOf(PropTypes.oneOf(Object.values(SortingColumn)).isRequired),
  folder: PropTypes.number,
  features: PropTypes.arrayOf(PropTypes.oneOf(Object.values(Features)).isRequired),
  filters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(Filter)).isRequired),
  folderActions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(FolderAction)).isRequired),
  sessionActions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(SessionAction)).isRequired),
}

export default SessionsPage
