import { takeLatest, select, all, put } from 'redux-saga/effects'

import { TenantSessionsMode } from '@tabeeb/enums'
import { getShowAdvancedFilterButton } from '@tabeeb/modules/appConfigState/selectors'
import * as tenantSessionsSelectors from '@tabeeb/modules/tenantSessionsPage/selectors'
import { openSession } from '@tabeeb/modules/sessionsPage/actions'
import { getIsSncFiltersEnabled, getSncFilterModel } from '@tabeeb/modules/sncFilters/selectors'

import * as rawActions from '../actions'
import { mappedSortingColumns } from '../services/enums'

function redirectToSession(action) {
  const contentId = action.payload
  const link = `${window.location.origin}/whiteboard/plugin?sessionId=${contentId}`
  window.open(link, '_blank')
}

export function* getFilterModel(mode = TenantSessionsMode.Actual) {
  const {
    dateTimeFilter: { startDate, endDate },
    generalFilter,
    sorting,
    filtration,
  } = yield select((state) => state.tenantSessionsPage)

  const showAdvancedFilterButton = yield select(getShowAdvancedFilterButton)
  const sncFilterEnabled = yield select(getIsSncFiltersEnabled)
  const useSncFilters = sncFilterEnabled && showAdvancedFilterButton
  let sncFiltersModel = []
  if (useSncFilters) {
    sncFiltersModel = yield select(getSncFilterModel)
  }

  const sortingParams = {
    Column: mappedSortingColumns[sorting.Column],
    Order: sorting.Order,
  }

  const filterModel = {
    Sorting: sortingParams,
    Filtering: {
      GeneralFilter: generalFilter,
      CreatedOn: {
        StartDate: startDate,
        EndDate: endDate,
      },
      NamePartsQuestionAnswers: sncFiltersModel,
      SncFiltersEnabled: useSncFilters,
    },
  }

  if (mode === TenantSessionsMode.Actual) {
    filterModel.Filtering = {
      ...filterModel.Filtering,
      ...filtration,
    }
  }

  return filterModel
}

export function validateDateRange(startDate, endDate, expectedMaxRange) {
  const oneDay = 86400000 // milliseconds
  const daysRange = Math.round(Math.abs((endDate - startDate) / oneDay))

  return daysRange <= expectedMaxRange
}

function* handleSessionNameClick(action) {
  const contentId = action.payload

  const content = yield select((state) => tenantSessionsSelectors.getContentById(state, { id: contentId }))
  if (!content) {
    return
  }

  yield put(openSession({ session: { ...content, Id: contentId }, target: '_blank' }))
}

function* initTenantContentsPage(action) {
  const isCustomDashboardsEnabled = yield select(tenantSessionsSelectors.getIsCustomDashboardsEnabled)

  if (isCustomDashboardsEnabled) {
    yield put(rawActions.getTenantContentsDashboards.request())
    return
  }

  yield put(rawActions.loadTenantContents())
}

function* common() {
  yield all([
    yield takeLatest(rawActions.redirectToSession, redirectToSession),
    yield takeLatest(rawActions.handleSessionNameClick, handleSessionNameClick),
    yield takeLatest(rawActions.initTenantContentsPage, initTenantContentsPage),
  ])
}

export default common
