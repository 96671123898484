import { put, takeLatest, select, take, all } from 'redux-saga/effects'
import { generatePath } from 'react-router-dom'
import { push } from 'connected-react-router'

import { signalrActions } from '@tabeeb/modules/signalr'
import routes from '@tabeeb/routes'
import { ContentState, SessionNamingConventionType } from '@tabeeb/enums'
import { getUseSessionNameConvention } from '@tabeeb/modules/appConfigState/selectors'
import { tenantFormsSelectors } from '@tabeeb/modules/tenantForms'
import { openGeoserchThumbnailsDialog } from '@tabeeb/modules/geosearch/actions'

import * as rawActions from '../actions/index'
import * as tenantFormActions from '../../tenantForms/actions'
import * as notificationActions from '../../notification/actions'

import joinContentReview from './joinContentReview'
import shareSession from './shareSession'
import publishSession from './publishSession'
import sessionsList from './sessionsList'
import foldersList from './foldersList'
import generalFilter from './generalFilter'
import removeSession from './removeSession'
import targetContent from './targetContent'
import usersSearch from './usersSearch'

import { accountSelectors } from '../../account'
import getErrorMessageFromResponse from '../../shared/utils/getErrorMessageFromResponse'
import { getCurrentFolder, getIsNeedPermissionToActivate, getIsTrashOpen } from '../selectors'
import { getSelectedStorageOrDefault } from '../selectors/storages'
import updateFolderUser from './updateFolderUser'

import selectedSessionsSagas from './selectedSessionsSagas'

function* createSession(action) {
  const { needPermission, suppressRedirect } = action.payload
  const useSessionNameConvention = yield select(getUseSessionNameConvention)
  let sessionName = ''
  let contentNameParts = []

  if (useSessionNameConvention) {
    yield put(tenantFormActions.openTenantFormsDialog({ sncType: SessionNamingConventionType.CreateSession }))
    yield take([tenantFormActions.submitTenantFormAnswers])

    contentNameParts = yield select(tenantFormsSelectors.getContentNameParts)
    sessionName = yield select(tenantFormsSelectors.getSubmittedAnswers)

    yield put(tenantFormActions.closeTenantFormsDialog())
  }

  const currentFolder = yield select(getCurrentFolder)
  const selectedStorageOrDefault = yield select(getSelectedStorageOrDefault)

  const data = {
    FolderId: currentFolder != null ? currentFolder.Id : null,
    Description: sessionName,
    ContentNameParts: contentNameParts,
    StorageGroupId: selectedStorageOrDefault?.StorageGroupId,
  }

  const creator = yield select(accountSelectors.getMe)

  yield put(rawActions.createSession.request({ data, needPermission, suppressRedirect, creator }))
}

function* createSessionSuccess(action) {
  const sessionId = action.response.data.ContentId
  const { needPermission } = action.payload
  const suppressRedirect = Boolean(action.payload.suppressRedirect)
  yield put(rawActions.setAdminPermissionFlag(needPermission))

  const isNeedPermissionToActivate = yield select(getIsNeedPermissionToActivate)
  const currentFolder = yield select(getCurrentFolder)
  if (!isNeedPermissionToActivate) {
    if (suppressRedirect) {
      return
    }

    currentFolder?.Id
      ? yield put(push(`${routes.session}?sessionId=${sessionId}&folderId=${currentFolder.Id}`))
      : yield put(push(`${routes.session}?sessionId=${sessionId}`))
  }
}

function* deleteSessionFailed({ response }) {
  const message = getErrorMessageFromResponse(response.data)
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* leaveSessionFailed({ response }) {
  const message = getErrorMessageFromResponse(response.data)
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* activateSession(action) {
  const { contentId } = action.payload

  const isTrashOpen = yield select(getIsTrashOpen)
  const isNeedPermissionToActivate = yield select(getIsNeedPermissionToActivate)
  const isGeosearchOpen = yield select(
    (state) => Boolean(state.geosearch) && state.geosearch.geosearchState.isGeosearchOpen
  )
  const needPermission = action.payload.needPermission || isNeedPermissionToActivate
  const currentFolder = yield select(getCurrentFolder)

  if (needPermission && !isTrashOpen) {
    yield put(rawActions.updateContentState({ Id: contentId, ContentStatus: ContentState.Pending }))
  } else if (isTrashOpen) {
    // do nothing
  } else if (isGeosearchOpen) {
    yield put(openGeoserchThumbnailsDialog())
  } else {
    currentFolder?.Id
      ? yield put(push(`${routes.session}?sessionId=${contentId}&folderId=${currentFolder.Id}`))
      : yield put(push(`${routes.session}?sessionId=${contentId}`))
  }
}

function* getFolderSuccess(action) {
  const { data } = action.response
  yield put(rawActions.setCurrentFolder(data))
}

function* getFolderFailed(action) {
  yield put(rawActions.setCurrentFolder({}))
}

function* openFolder(action) {
  const folder = action.payload

  if (folder.Id) {
    yield put(signalrActions.invokeHubAction({ method: 'JoinFolder', args: [folder.Id] }))
  } else {
    const folderId = yield select((state) => state.sessionsPage.currentFolder.Id)
    yield put(signalrActions.invokeHubAction({ method: 'LeaveFolder', args: [folderId] }))
  }

  yield put(push(generatePath(routes.sessionsFolder, { folderId: folder.Id })))
  yield put(rawActions.setSessionsList([]))
}

function* getIsFolderParticipantSuccess(action) {
  const { folderId } = action.payload
  const isFolderParticipant = action.response.data

  if (isFolderParticipant) yield put(rawActions.getFolder.request(folderId))
  else {
    yield put(push(routes.home))
    yield put(
      notificationActions.onAddErrorNotification({
        message: 'Content not found!',
      })
    )
  }
}

function* switchSessionFolderFailed(action) {
  yield put(notificationActions.onAddErrorNotification({ message: 'This action is not available' }))
}

function* sessionsPageSaga() {
  yield all([
    takeLatest(rawActions.initiateSessionCreation, createSession),
    takeLatest(rawActions.createSession.success, createSessionSuccess),
    takeLatest(rawActions.deleteSession.failed, deleteSessionFailed),
    takeLatest(rawActions.leaveSession.failed, leaveSessionFailed),
    takeLatest(rawActions.activateSession.success, activateSession),
    takeLatest(rawActions.getFolder.success, getFolderSuccess),
    takeLatest(rawActions.getFolder.failed, getFolderFailed),
    takeLatest(rawActions.openFolder, openFolder),
    takeLatest(rawActions.getIsFolderParticipant.success, getIsFolderParticipantSuccess),
    takeLatest(rawActions.switchSessionFolder.failed, switchSessionFolderFailed),
    shareSession(),
    joinContentReview(),
    publishSession(),
    sessionsList(),
    foldersList(),
    generalFilter(),
    removeSession(),
    updateFolderUser(),
    targetContent(),
    usersSearch(),
    // background
    selectedSessionsSagas(),
  ])
}

export default sessionsPageSaga
