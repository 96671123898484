import { createSelector } from 'reselect'

import { SessionPermission } from '@tabeeb/enums'
import { permissionsSelectors } from '@tabeeb/modules/permissions'
import { getIsTrackingMapEnabled } from '@tabeeb/modules/appConfigState/selectors'

import { TrackingFilterTypes } from '../constants'

export const getIsOpen = (state) => state.tracking.isOpen
export const getIsLoading = (state) => state.tracking.isLoading
export const getTrackings = (state) => state.tracking.trackings
export const getFilters = (state) => state.tracking.filtration

export const getIsTrackingEnabled = getIsTrackingMapEnabled
export const getHasToolbarTrackingPermission = (state) =>
  permissionsSelectors.hasSessionPermission(state, SessionPermission.MainScreenToolbarTracking)

export const getFilterByName = (state, name) => getFilters(state)[name]

export const getFilteredTrackings = createSelector([getTrackings, getFilters], (trackings, filters) => {
  let filteredTrackings = trackings

  if (filters[TrackingFilterTypes.TrackingTypes].isEnabled) {
    filteredTrackings = filteredTrackings.filter((item) =>
      filters[TrackingFilterTypes.TrackingTypes].value.includes(item.AnnotationType)
    )
  }

  if (filters[TrackingFilterTypes.EventTypes].isEnabled) {
    filteredTrackings = filteredTrackings.filter((item) =>
      filters[TrackingFilterTypes.EventTypes].value.includes(item.EventType)
    )
  }

  if (filters[TrackingFilterTypes.TimePeriod].isEnabled) {
    filteredTrackings = filteredTrackings.filter((item) => {
      const trackingCreationTimestamp = item.CreatedOn.endsWith('Z')
        ? new Date(item.CreatedOn)
        : new Date(`${item.CreatedOn}Z`)
      const filterValue = filters[TrackingFilterTypes.TimePeriod].value
      return (
        new Date(filterValue.start) <= trackingCreationTimestamp &&
        trackingCreationTimestamp <= new Date(filterValue.end)
      )
    })
  }

  return filteredTrackings
})

export const getIsTrackingsButtonVisible = createSelector(
  [getIsTrackingEnabled, getHasToolbarTrackingPermission],
  (isTrackingEnabled, hasPermission) => {
    return isTrackingEnabled && hasPermission
  }
)
