import { put, takeLatest, select, take, all } from 'redux-saga/effects'
import { getFormTitleLowerCase } from '@tabeeb/modules/appConfigState/selectors'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { seekVideoToTimestamp } from '@tabeeb/modules/player/actions'
import { getIsCurrentUserReviewer } from '@tabeeb/modules/../users/selectors'
import { onAddSuccessNotification } from '@tabeeb/modules/notification/actions'
import {
  deleteGalleryItemSuccess,
  onDeleteGalleryItem,
  onGalleryItemSwitched,
  onSelectGalleryItem,
  selectGalleryItem,
} from '@tabeeb/modules/gallery/actions'
import { onContentReviewCompleted, onReviewFeedbackSent } from '@tabeeb/modules/contentReviews/actions'
import moment from 'moment-timezone'
import { DisplayAnswersMode } from '../../../Enums'
import { signalrEvents, signalrConstants } from '../../signalr'
import { formTabsIdsEquals } from '../services/formTabService'
import { answersValuesAreEqual } from '../services/compareAnswersService'
import { getCurrentUserReview } from '../../contentReviews/selectors'
import { FormAnswersFromFileLoadingStatus } from '../services/enums'
import {
  getAnswerStatus,
  getCurrentForm,
  getDisplayAnswersMode,
  getSelectedGalleryItemId,
  getSelectedUserId,
} from '../selectors'
import {
  addFormAnswers,
  addFormAnswersFromFile,
  addFormAnswersFromFileFailed,
  addFormAnswersFromFileRequest,
  addFormAnswersFromFileSuccess,
  clearFormAnswers,
  getAllMyLatestAnswers,
  getCounterInfo,
  getFormAnswersByRecordRequest,
  getFormAnswersByRecordSuccess,
  getFormAnswersRequest,
  getFormAnswersSuccess,
  getLatestFormAnswers,
  getLatestFormAnswersRequest,
  getLatestFormAnswersSuccess,
  getLatestTabsAnswersRequest,
  getLatestTabsAnswersSuccess,
  getTabsAnswersRequest,
  getTabsAnswersSuccess,
  proceedToControlAnswerPage,
  sendFormAnswerRequest,
  sendFormAnswerSuccess,
  setAnswersStatusesCount,
  setDisplayAnswersMode,
  setFormAnswers,
  setFormAnswersFromFileLoadingState,
  setFormAnswersLoading,
  setSelectedRecord,
  setSelectedTabs,
  setSelectedUserId,
  updateAnswerStatus,
  updateFormAnswers,
} from '../actions'

function* onAddFormAnswers(action) {
  const { control, newAnswer, prevAnswer } = action.payload

  if (answersValuesAreEqual(prevAnswer, newAnswer)) return

  const currentForm = yield select(getCurrentForm)
  const currentPageId = yield select(getSelectedGalleryItemId)
  const contentId = yield select(getContentId)
  const timestamp = yield select((state) => state.player.currentVideoTimestamp)

  const selectedTabs = currentForm?.selectedTabs || []

  let answerInfo = {
    ContentId: contentId,
    ContentFormId: currentForm.ContentFormId,
    FormControlId: control.Id,
    PageId: currentPageId,
    Timestamp: timestamp,
    TimeZone: moment.tz.guess(),
  }

  const { value, selectedOptionsIds } = newAnswer

  if (selectedOptionsIds?.length > 0) answerInfo = { ...answerInfo, OptionsIds: selectedOptionsIds }
  if (value) answerInfo = { ...answerInfo, Value: value }
  if (selectedTabs.length > 0) answerInfo = { ...answerInfo, FormTabsIds: selectedTabs }

  yield put(sendFormAnswerRequest(answerInfo))
}

function* onGetAllMyLatestAnswers() {
  const currentForm = yield select(getCurrentForm)
  const currentUserId = yield select(getCurrentUserId)
  const selectedTabs = currentForm.selectedTabs || []
  yield put(setSelectedUserId(currentUserId))

  if (selectedTabs?.length > 0) {
    yield put(
      getTabsAnswersRequest({
        currentFormId: currentForm.Id,
        selectedUserId: currentUserId,
        formTabs: selectedTabs.map((tabId) => `&formTabs=${tabId}`).join(''),
        pageId: null,
      })
    )
  } else {
    yield put(
      getFormAnswersRequest({
        contentFormId: currentForm.Id,
        userId: currentUserId,
        pageId: null,
      })
    )
  }
}

function* onSetFormAnswers(action) {
  const answers = action.response.data
  const isReviewer = yield select(getIsCurrentUserReviewer)
  const review = yield select(getCurrentUserReview)

  answers.forEach((answer) => {
    answer.FormAnswerStatus = getAnswerStatus(answer, isReviewer, review)
  })

  yield put(setFormAnswers(answers))
}

function* onAddFormAnswersFromFileSuccess(action) {
  const answers = action.response.data

  for (let i = 0; i < answers.length; i++) {
    answers[i].TimeZone = moment.tz.guess()
    yield put(sendFormAnswerRequest(answers[i]))
    yield take([sendFormAnswerSuccess])
  }

  const formTitle = yield select(getFormTitleLowerCase)

  yield put(setFormAnswersFromFileLoadingState({ status: FormAnswersFromFileLoadingStatus.Loaded }))
  yield put(onAddSuccessNotification({ message: `Answers added to the ${formTitle} successfully` }))

  yield put(setFormAnswersFromFileLoadingState({ status: FormAnswersFromFileLoadingStatus.None }))
}

function* onAddFormAnswersFromFileFailed(action) {
  const error = action.response.data.Error
  const formTitle = yield select(getFormTitleLowerCase)
  let errors = []
  if (!error || !error.Details) errors = [`Failed to add answers from file to the ${formTitle}`]
  else errors = error.Details.split(';')

  yield put(setFormAnswersFromFileLoadingState({ status: FormAnswersFromFileLoadingStatus.Failed, errors }))
}

function* onProceedToControlAnswerPage(action) {
  const control = action.payload
  const { currentForm } = yield select((state) => state.forms)

  const answer = currentForm.answers.find((a) => a.FormControlId === control.Id)
  if (!answer) {
    return
  }

  const { selectedRecord } = currentForm
  if (selectedRecord) {
    return
  }

  const selectedGalleryItemId = yield select(getSelectedGalleryItemId)
  if (selectedGalleryItemId !== answer.PageId) {
    yield put(onSelectGalleryItem({ pageId: answer.PageId, timestamp: answer.Timestamp, updateWeb: true }))
    yield take([onGalleryItemSwitched])
  } else if (answer.Timestamp >= 0) {
    yield put(seekVideoToTimestamp(answer.Timestamp * 1000))
  }
}

function* onGetLatestFormAnswers() {
  const { currentForm } = yield select((state) => state.forms)
  const tabsPanels = currentForm.Tabspanels || []
  const selectedTabs = currentForm.selectedTabs || tabsPanels.map((panel) => panel.Tabs[0].Id)

  let formTabs

  if (Array.isArray(selectedTabs)) {
    formTabs = selectedTabs.map((tabId) => `&formTabs=${tabId}`).join('')
  }

  const isReviewer = yield select(getIsCurrentUserReviewer)
  const isFormHasTabs = tabsPanels.length > 0
  let contentSharingId = null

  if (isReviewer) {
    const review = yield select((state) => getCurrentUserReview(state))

    if (!review) return
    contentSharingId = review.Id
  }

  if (isFormHasTabs) {
    yield put(
      getLatestTabsAnswersRequest({
        contentFormId: currentForm.Id,
        formTabs,
        contentSharingId,
      })
    )
  } else {
    yield put(
      getLatestFormAnswersRequest({
        contentFormId: currentForm.Id,
        contentSharingId,
      })
    )
  }
}

function* onGetFormAnswers() {
  const selectedUserId = yield select(getSelectedUserId)
  const displayAnswersMode = yield select(getDisplayAnswersMode)
  const currentForm = yield select(getCurrentForm)
  const { selectedGalleryItemId } = yield select((state) => state.gallery.galleryState)

  if (!currentForm.ContentFormId) return

  yield put(setFormAnswersLoading(true))
  yield put(getCounterInfo())

  const selectedTabs = currentForm.selectedTabs || []
  const selectedRecord = currentForm.selectedRecord || 0

  if (selectedRecord > 0) {
    yield put(
      getFormAnswersByRecordRequest({
        recordId: selectedRecord,
        formTabs: selectedTabs.map((tabId) => `&formTabs=${tabId}`).join(''),
      })
    )
  } else {
    switch (displayAnswersMode) {
      case DisplayAnswersMode.AllLatest:
        yield put(getLatestFormAnswers())
        break
      case DisplayAnswersMode.AllMyLatest:
        yield put(getAllMyLatestAnswers())
        break
      default:
        if (selectedTabs?.length > 0) {
          yield put(
            getTabsAnswersRequest({
              currentFormId: currentForm.Id,
              selectedUserId,
              formTabs: selectedTabs.map((tabId) => `&formTabs=${tabId}`).join(''),
              pageId: selectedGalleryItemId,
            })
          )
        } else {
          yield put(
            getFormAnswersRequest({
              contentFormId: currentForm.Id,
              userId: selectedUserId,
              pageId: selectedGalleryItemId,
            })
          )
        }
    }
  }
}

function* onAnswersCreated(action) {
  const isReviewer = yield select(getIsCurrentUserReviewer)
  const selectedUserId = yield select(getSelectedUserId)
  const displayAnswersMode = yield select(getDisplayAnswersMode)
  const currentForm = yield select(getCurrentForm)
  const currentPageId = yield select(getSelectedGalleryItemId)

  const selectedRecord = currentForm.selectedRecord || 0
  const { ContentFormId } = currentForm
  const selectedTabs = currentForm.selectedTabs || []
  const [createdAnswer] = action.payload

  const answerTabs =
    createdAnswer.FormTabAnswers !== null ? createdAnswer.FormTabAnswers.map((item) => item.FormTabId) : []

  const isCurrentTabsAnswer = formTabsIdsEquals(answerTabs, selectedTabs)

  if (!isCurrentTabsAnswer || createdAnswer.ContentFormId !== ContentFormId || selectedRecord > 0 || isReviewer) {
    return
  }

  const isAllLatest = displayAnswersMode === DisplayAnswersMode.AllLatest

  const isAllMyLatest = displayAnswersMode === DisplayAnswersMode.AllMyLatest && createdAnswer.UserId === selectedUserId

  const isByUser =
    displayAnswersMode === DisplayAnswersMode.ByUser &&
    createdAnswer.UserId === selectedUserId &&
    createdAnswer.PageId === currentPageId

  if (isAllLatest || isAllMyLatest || isByUser) yield put(updateFormAnswers(createdAnswer))
}

function* onAnswerStatusUpdated(action) {
  const [updatedAnswers] = action.payload

  const { currentForm } = yield select((state) => state.forms)
  const isReviewer = yield select(getIsCurrentUserReviewer)
  const selectedRecord = currentForm.selectedRecord || 0

  if (selectedRecord > 0 || currentForm.answers?.length === 0 || isReviewer) return
  yield put(updateAnswerStatus(updatedAnswers))
}

function* onAnswersCleared(action) {
  const [model] = action.payload

  const { currentForm, displayAnswersMode } = yield select((state) => state.forms)
  const selectedRecord = currentForm.selectedRecord || 0

  if (selectedRecord > 0) return

  const answer = currentForm.answers.find((item) => item.Id === model.FormAnswerId)

  if (answer) {
    yield put(clearFormAnswers(answer))

    if (displayAnswersMode === DisplayAnswersMode.AllLatest) {
      yield put(getLatestFormAnswers())
    } else if (displayAnswersMode === DisplayAnswersMode.AllMyLatest) {
      yield put(getAllMyLatestAnswers())
    }
  }
}

function* onAnswerStatusStatisticsUpdated(action) {
  const [statistics, contentFormId] = action.payload

  const { currentForm } = yield select((state) => state.forms)
  if (currentForm.ContentFormId !== contentFormId) return

  yield put(setAnswersStatusesCount(statistics.FormAnswerStatuses))
}

function* onAddFormAnswersFromFile(action) {
  const { formData, timeZoneOffsetInMinutes } = action.payload

  const currentForm = yield select(getCurrentForm)
  const timestamp = yield select((state) => state.player.currentVideoTimestamp)
  const currentPageId = yield select(getSelectedGalleryItemId)

  const data = {
    formData,
    contentFormId: currentForm.Id,
    pageId: currentPageId,
    timestamp,
    timeZoneOffsetInMinutes,
  }

  yield put(addFormAnswersFromFileRequest(data))
}

function* formAnswers() {
  yield all([
    takeLatest(getAllMyLatestAnswers, onGetAllMyLatestAnswers),
    takeLatest(
      [
        getFormAnswersSuccess,
        getLatestFormAnswersSuccess,
        getFormAnswersByRecordSuccess,
        getTabsAnswersSuccess,
        getLatestTabsAnswersSuccess,
      ],
      onSetFormAnswers
    ),
    takeLatest(addFormAnswersFromFileSuccess, onAddFormAnswersFromFileSuccess),
    takeLatest(addFormAnswersFromFileFailed, onAddFormAnswersFromFileFailed),
    takeLatest([getLatestFormAnswers, onReviewFeedbackSent, onContentReviewCompleted], onGetLatestFormAnswers),
    takeLatest(addFormAnswers, onAddFormAnswers),
    takeLatest(
      [
        setSelectedRecord,
        setDisplayAnswersMode,
        setSelectedTabs,
        selectGalleryItem,
        deleteGalleryItemSuccess,
        onDeleteGalleryItem,
        signalrEvents[signalrConstants.tabeebHubName].onPagesRestored,
      ],
      onGetFormAnswers
    ),
    takeLatest(proceedToControlAnswerPage, onProceedToControlAnswerPage),
    takeLatest(addFormAnswersFromFile, onAddFormAnswersFromFile),

    // signalr
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onAnswersCreated, onAnswersCreated),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onAnswersCleared, onAnswersCleared),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onAnswerStatusUpdated, onAnswerStatusUpdated),
    takeLatest(
      signalrEvents[signalrConstants.tabeebHubName].onAnswerStatusStatisticsUpdated,
      onAnswerStatusStatisticsUpdated
    ),
  ])
}

export default formAnswers
