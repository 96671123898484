import { useCallback, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Grid } from '@mui/material'

import { useSelectedItemMenu } from '@tabeeb/shared/utils/hooks'
import { getFoldersList, getIsFeatureEnabled, getIsFoldersListHidden } from '@tabeeb/modules/sessionsPage/selectors'

import Folder from './Folder'
import { FolderMenu } from '../Menus'

import { openFolder, openSessionsPageDialog, switchSessionFolder } from '../../actions'

import { DialogTypes, Features } from '../../enums'
import { getShowFolderMenu } from '../../helpers'

const Folders = () => {
  const dispatch = useDispatch()
  const folders = useSelector(getFoldersList)

  const [anchorEl, selectedFolder, open, onOpen, onClose] = useSelectedItemMenu()

  const onClick = useCallback(
    (e, folder) => {
      if (folder.IsDeleted) {
        dispatch(openSessionsPageDialog({ dialogType: DialogTypes.RestoreFolder, dialogPayload: { folder } }))

        return
      }

      dispatch(openFolder(folder))
    },
    [dispatch]
  )

  const onMenu = useCallback(
    (e, folder) => {
      onOpen({ anchor: e.target, item: folder })
    },
    [onOpen]
  )

  const onDrop = useCallback(
    (e, folder) => {
      if (e.preventDefault) {
        e.preventDefault()
      }
      if (e.stopPropagation) {
        e.stopPropagation()
      }

      const session = JSON.parse(e.dataTransfer.getData('text'))
      if (!session?.Id) {
        return
      }

      dispatch(
        switchSessionFolder.request({
          contentId: session.Id,
          fromFolderId: null,
          toFolderId: folder.Id,
        })
      )
    },
    [dispatch]
  )

  const visible = useSelector((state) => getIsFeatureEnabled(state, Features.Folders) && !getIsFoldersListHidden(state))
  if (!visible) {
    return null
  }

  return (
    <>
      <Grid component='section' container columnSpacing={2.5} rowSpacing={2} mb={4}>
        {folders.map((folder) => (
          <Grid key={folder.Id} item xs={6} md={3} lg={3} xl={2.4}>
            <Folder
              folder={folder}
              onClick={onClick}
              onDrop={onDrop}
              onMenu={getShowFolderMenu(folder) ? onMenu : undefined}
            />
          </Grid>
        ))}
      </Grid>

      {selectedFolder && <FolderMenu anchorEl={anchorEl} open={open} onClose={onClose} folder={selectedFolder} />}
    </>
  )
}

export default memo(Folders)
