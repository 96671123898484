import { HttpGet } from '@tabeeb/shared/utils/API/httpClient'

const basePath = 'api/chatMessage'

export async function getUnreadUserChatsCountRequest(): Promise<Number> {
  const response = await HttpGet<{ unread: Boolean }, Number>({
    path: `${basePath}/chats/byuser/count`,
    params: {
      unread: true,
    },
  })

  return response.data
}
