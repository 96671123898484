import { handleActions, ReduxCompatibleReducer } from 'redux-actions'

import { persistReducer } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'

import { TenantConfig } from '@tabeeb/contracts/microservices/swagger-tenant-service'

import { onSetAppConfigState, getConfig } from '../actions'
import { defaultConfig } from '../constants'

export default persistReducer(
  {
    key: 'appConfigState',
    storage,
  },
  handleActions<TenantConfig, any>(
    {
      [onSetAppConfigState.toString()](state, action) {
        const typedAction = action as ReturnType<typeof onSetAppConfigState>

        const config = typedAction.payload

        return { ...state, ...config }
      },
      [getConfig.success.toString()](state, action) {
        const typedAction = action as ReturnType<typeof getConfig.success>

        const config = typedAction.response.data

        return { ...defaultConfig, ...config }
      },
    },
    defaultConfig
  )
) as ReduxCompatibleReducer<TenantConfig, any>
