import { put, takeLatest, select, all } from 'redux-saga/effects'

import { getShowSwitchToAttachedFormDialog } from '@tabeeb/modules/appConfigState/selectors'

import * as rawActions from '../actions'
import { signalrEvents, signalrConstants } from '../../signalr'
import { getContentFormsForCurrentUser, getCurrentForm } from '../selectors'

function* setContentForms(action) {
  const { data } = action.response
  yield put(rawActions.updateContentForms(data))
  const currentForm = yield select(getCurrentForm)
  const forms = yield select(getContentFormsForCurrentUser)

  yield put(rawActions.updateContentForms(forms))
  if (currentForm.Id && forms.some((cf) => cf.Id === currentForm.Id)) {
    if (forms.length > 0) {
      yield put(rawActions.getFormControlsRequest({ contentFormId: currentForm.Id }))
    }
  } else if (forms.length > 0) {
    yield put(rawActions.getFormControlsRequest({ contentFormId: forms[0].Id }))
  }
}

function* getContentFormSuccess(action) {
  let showSwitchToAttachedFormDialog = yield select(getShowSwitchToAttachedFormDialog)
  const form = action.response.data
  if (!form) {
    return
  }
  yield put(rawActions.addContentForm(form))
  const { contentForms, lastAttachedForm, infoMessageCondition } = yield select((state) => state.forms)

  if (contentForms.length === 1) {
    yield put(rawActions.getFormControlsRequest({ contentFormId: contentForms[0].Id }))
  }

  if (lastAttachedForm.isAttachedFromCondition && !infoMessageCondition.isDialogOpen) {
    if (typeof showSwitchToAttachedFormDialog === 'undefined') showSwitchToAttachedFormDialog = true

    if (showSwitchToAttachedFormDialog) {
      yield put(rawActions.openSwitchFormDialog())
    }
  }
}

function* checkLastAttachedForm(action) {
  let showSwitchToAttachedFormDialog = yield select(getShowSwitchToAttachedFormDialog)
  const { lastAttachedForm } = yield select((state) => state.forms)

  if (lastAttachedForm.isAttachedFromCondition) {
    if (typeof showSwitchToAttachedFormDialog === 'undefined') showSwitchToAttachedFormDialog = true
    if (showSwitchToAttachedFormDialog) {
      yield put(rawActions.openSwitchFormDialog())
    }
  }
}

function* onFormRecordCreated(action) {
  const [model] = action.payload
  const { currentForm } = yield select((state) => state.forms)

  if (model.ContentFormId === currentForm.Id) {
    yield put(rawActions.addFormRecord(model))
  }
}

function* onFormRecordDeleted(action) {
  const [model] = action.payload
  const { currentForm } = yield select((state) => state.forms)

  if (model.ContentFormId === currentForm.Id) {
    yield put(rawActions.removeFormRecord(model.Id))

    if (currentForm.selectedRecord === model.Id) {
      yield put(rawActions.setSelectedRecord(0))
    }
  }
}

function* contentFormsSaga() {
  yield all([
    takeLatest(rawActions.getContentFormsSuccess, setContentForms),
    takeLatest(rawActions.getContentFormSuccess, getContentFormSuccess),
    takeLatest(rawActions.checkLastAttachedForm, checkLastAttachedForm),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onFormRecordCreated, onFormRecordCreated),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onFormRecordDeleted, onFormRecordDeleted),
  ])
}

export default contentFormsSaga
