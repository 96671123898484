import { Skeleton, Stack } from '@mui/material'
import { Masonry, Section } from '@tabeeb/uikit'
import range from 'lodash/range'

const NotificationSettingsSkeleton = () => {
  return (
    <Masonry columns={2} spacing={3}>
      {range(4).map((index) => (
        <Section key={index} title=''>
          <Stack spacing={2.5}>
            {range(3).map((notificationSetting) => (
              <Skeleton key={notificationSetting} variant='rounded' height={30} />
            ))}
          </Stack>
        </Section>
      ))}
    </Masonry>
  )
}

export default NotificationSettingsSkeleton
