import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import InfoIcon from '@material-ui/icons/Info'

import { getIsControlInfoEnabled } from '@tabeeb/modules/appConfigState/selectors'

import FormControlInfoWindow from '../FormControlInfoWindow'
import FormControlButton from '../FormControlButton'

const FormControlInfoButton = ({ control, isVisible }) => {
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const _handleInfoIconClick = (event) => {
    setIsInfoWindowOpen(!isInfoWindowOpen)
    const anchorEl = event.currentTarget
    setAnchorEl(anchorEl)
  }

  const dialogProps = {
    onCloseDialog: () => setIsInfoWindowOpen(false),
    isOpen: isInfoWindowOpen,
    anchorEl,
    control,
  }

  const componentProps = {
    onClick: _handleInfoIconClick,
    Icon: InfoIcon,
    title: 'Question info',
    iconColor: '#00BCD4',
  }

  if (!isVisible) return null

  return (
    <>
      <FormControlButton {...componentProps} />
      {isInfoWindowOpen && <FormControlInfoWindow {...dialogProps} />}
    </>
  )
}

FormControlInfoButton.propTypes = {
  control: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, props) => {
  return {
    isVisible: getIsControlInfoEnabled(state) && !!props.control.Info,
  }
}

export default connect(mapStateToProps, null)(FormControlInfoButton)
