import { put, takeLatest, select, all } from 'redux-saga/effects'

import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'
import * as contentStateSelectors from '@tabeeb/modules/shared/content/selectors'
import { notifySystem } from '@tabeeb/modules/shared/content/services/notificationService'
import * as callNotificationActions from '@tabeeb/modules/callNotification/actions'
import * as callNotificationSelectors from '@tabeeb/modules/callNotification/selectors'
import * as accountSelectors from '@tabeeb/modules/account/selectors'
import * as whiteboardActions from '@tabeeb/modules/whiteboard/actions'
import { openCallPreviewDialog } from '@tabeeb/modules/presentation/actions/conference'
import { enterRoom } from '@tabeeb/modules/whiteboard/services'
import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import { switchPanel } from '@tabeeb/modules/sessionTabs/actions'
import { getIsFormsAndUsersTabCollapsed } from '@tabeeb/modules/appConfigState/selectors'

function* onCallStarted(action) {
  const [model] = action.payload

  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  if (currentUserId === model.PresenterId) {
    return
  }

  const sessionId = yield select(contentStateSelectors.getContentId)

  if (sessionId === model.SessionId) {
    const currentBottomPanel = yield select((state) => state.navbar.currentPanel.bottom)
    const isFormsAndUsersTabCollapsed = yield select(getIsFormsAndUsersTabCollapsed)

    if (isFormsAndUsersTabCollapsed && currentBottomPanel !== TabPanel.Users) {
      yield put(switchPanel(TabPanel.Users, TabPanelLocation.Bottom))
    }

    yield enterRoom(model.SessionId, model.PresenterId)
    return
  }

  yield put(callNotificationActions.addCallNotification(model))
}

function* onCallEnded(action) {
  const [model] = action.payload
  const { sessionId } = model

  const notification = yield select((state) => callNotificationSelectors.callNotificationSelector(state, { sessionId }))
  if (!notification) {
    return
  }

  yield put(callNotificationActions.hideCallNotification(sessionId))
}

function* showSystemCallNotification(action) {
  const notificationOptions = action.payload
  notifySystem(notificationOptions)
}

function* handleHideStartCallNotification(action) {
  const sessionId = yield select((state) => contentStateSelectors.getContentId(state))
  const notification = yield select((state) => callNotificationSelectors.callNotificationSelector(state, { sessionId }))

  if (notification && sessionId === notification.sessionId) {
    yield put(callNotificationActions.hideCallNotification(sessionId))
  }
}

function* callNotificationSaga() {
  yield all([
    takeLatest(callNotificationActions.showSystemCallNotification, showSystemCallNotification),
    takeLatest([whiteboardActions.openMobileJoinCallDialog, openCallPreviewDialog], handleHideStartCallNotification),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onCallStarted, onCallStarted),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onCallEnded, onCallEnded),
  ])
}

export default callNotificationSaga
