import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import _, { uniqueId } from 'lodash'

import { intersects } from '@tabeeb/modules/pointCloud/utils/shape'

import {
  addUniqueAiObjectConnection,
  autofillPageUniqueAiObjects,
  deleteUniqueAiObjectConnection,
} from '@tabeeb/modules/artificialIntelligence/actions'
import {
  createGetPageUniqueAIObjectsConnectionsSelector,
  createGetPageUniqueAIObjectsWithAnnotationsSelector,
} from '@tabeeb/modules/artificialIntelligence/selectors'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'
import { getUnit, getWorldRotation } from '@tabeeb/modules/pointCloud/selectors'

import { callApiAsync } from '@tabeeb/shared/utils/requests'
import { SystemOfMeasures, UniqueAIObjectConnectionType } from '@tabeeb/enums'
import { Units } from '@tabeeb/modules/pointCloud/constants'

const Mode = {
  View: 'view',
  Edit: 'edit',
}

const useUniqueAIObjectsHierarchy = (pageId) => {
  const dispatch = useDispatch()

  const [loading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [mode, setMode] = useState(Mode.View)
  const editing = mode === Mode.Edit

  const worldRotation = useSelector(getWorldRotation)
  const unit = useSelector(getUnit)

  const objectsSelector = useMemo(() => createGetPageUniqueAIObjectsWithAnnotationsSelector(), [])
  const objects = useSelector((state) => objectsSelector(state, { pageId }))

  const connectionsSelector = useMemo(() => createGetPageUniqueAIObjectsConnectionsSelector(), [])
  const connections = useSelector((state) => connectionsSelector(state, { pageId }))

  const [connectionsEditState, setConnectionsEditState] = useState(null)

  const displayConnections = useMemo(() => {
    if (editing) {
      return connectionsEditState
    }

    return connections
  }, [editing, connections, connectionsEditState])

  const onAutolink = useCallback(() => {
    const calculatedConnections = []

    const orderedObjects = _.orderBy(objects, (object) => object.level, 'desc')

    for (let i = 0; i < orderedObjects.length - 1; i++) {
      const currentObject = orderedObjects[i]

      for (let j = i + 1; j < orderedObjects.length; j++) {
        const nextObject = orderedObjects[j]
        if (nextObject.level >= currentObject.level) {
          continue
        }

        if (intersects(currentObject.shape, nextObject.shape, worldRotation)) {
          calculatedConnections.push({
            Id: uniqueId('new_'),
            ChildId: currentObject.uniqueAIObject.Id,
            ParentId: nextObject.uniqueAIObject.Id,
            Type: UniqueAIObjectConnectionType.Link,
          })

          break
        }
      }
    }

    setConnectionsEditState(calculatedConnections)
    setMode(Mode.Edit)
  }, [objects, worldRotation])

  const onAddConnection = useCallback(({ parentId, childId }) => {
    setConnectionsEditState((prevConnections) => {
      const nextConnections = prevConnections.filter((c) => c.ChildId !== childId)
      nextConnections.push({
        Id: uniqueId('new_'),
        ChildId: childId,
        ParentId: parentId,
        Type: UniqueAIObjectConnectionType.Link,
      })

      return nextConnections
    })
  }, [])

  const onRemoveConnection = useCallback(({ parentId, childId }) => {
    setConnectionsEditState((prevConnections) => {
      return prevConnections.filter((c) => !(c.ChildId === childId && c.ParentId === parentId))
    })
  }, [])

  const onEdit = useCallback(() => {
    setConnectionsEditState(connections)
    setMode(Mode.Edit)
  }, [connections])

  const onCancelEdit = useCallback(() => {
    setMode(Mode.View)
    setConnectionsEditState(connections)
  }, [connections])

  const onSave = useCallback(async () => {
    try {
      setSaving(true)

      const prevConnections = connections
      const nextConnections = connectionsEditState

      const toDelete = prevConnections
        .filter(
          (prevConnection) =>
            !nextConnections.some(
              (nextConnection) =>
                nextConnection.ChildId === prevConnection.ChildId && nextConnection.ParentId === prevConnection.ParentId
            )
        )
        .filter((connection) => {
          const isConnectionToParentOnAnotherPage = !objects.find((o) => o.uniqueAIObject.Id === connection.ParentId)

          return !isConnectionToParentOnAnotherPage
        })

      const toAdd = nextConnections.filter(
        (nextConnection) =>
          !prevConnections.some(
            (prevConnection) =>
              prevConnection.ChildId === nextConnection.ChildId && prevConnection.ParentId === nextConnection.ParentId
          )
      )

      for (const connection of toDelete) {
        // eslint-disable-next-line no-await-in-loop
        await callApiAsync(deleteUniqueAiObjectConnection.request({ uniqueAIObjectConnectionId: connection.Id }))
      }

      for (const connection of toAdd) {
        // eslint-disable-next-line no-await-in-loop
        await callApiAsync(addUniqueAiObjectConnection.request(connection))
      }

      dispatch(onAddSuccessNotification({ message: 'Hierarchy saved successfully' }))
    } catch (e) {
      dispatch(onAddErrorNotification({ message: 'Failed to save hierarchy' }))
    } finally {
      setSaving(false)
      setMode(Mode.View)
      setConnectionsEditState(null)
    }
  }, [connections, connectionsEditState, dispatch, objects])

  const hierarchy = useMemo(() => {
    const hierarchyObjects = []

    const visited = new Set()

    const orderedObjects = _.orderBy(objects, (object) => object.level, 'asc')

    const build = (object) => {
      visited.add(object.id)

      const children = orderedObjects.filter((o) =>
        displayConnections.some((c) => c.ParentId === object.id && c.ChildId === o.id)
      )

      const childrenObjects = []
      for (const child of children) {
        if (visited.has(child.id)) {
          continue
        }

        childrenObjects.push(build(child))
      }

      return {
        ...object,
        childrenObjects,
      }
    }

    for (const object of orderedObjects) {
      if (visited.has(object.id)) {
        continue
      }

      hierarchyObjects.push(build(object))
    }

    return hierarchyObjects
  }, [displayConnections, objects])

  const onAutofill = useCallback(async () => {
    try {
      setSaving(true)

      await callApiAsync(
        autofillPageUniqueAiObjects.request({
          pageId,
          unit: unit === Units.Meters ? SystemOfMeasures.Metric : SystemOfMeasures.Imperial,
        })
      )

      setSaving(false)

      dispatch(onAddSuccessNotification({ message: 'Properties successfully updated' }))
    } catch (e) {
      dispatch(onAddErrorNotification({ message: 'Failed to update properties' }))
    } finally {
      setSaving(false)
    }
  }, [dispatch, pageId, unit])

  return {
    loading,
    saving,
    hierarchy,
    editing,
    objects,
    connections,
    onAutofill,
    onAutolink,
    onAddConnection,
    onRemoveConnection,
    onEdit,
    onCancelEdit,
    onSave,
  }
}

export default useUniqueAIObjectsHierarchy
