import {
  DisableUserNotificationRequest,
  EnableUserNotificationRequest,
  NotificationTypeEnum,
  UserNotificationSettingResponse,
  UserNotificationSettingsResponse,
} from '@tabeeb/contracts/microservices/swagger-notifications-service'
import { HttpGet, HttpPut } from '@tabeeb/shared/utils/API/httpClient'
import { QueryFunctionContext } from '@tanstack/react-query'

export const AccountQueryKeys = {
  notificationSettings: 'notification-settings',
}

const notificationsServiceRootPath = 'notifications-service/api/user-notifications-settings'

export async function getNotificationSettingsRequest(): Promise<UserNotificationSettingsResponse> {
  const response = await HttpGet<void, UserNotificationSettingsResponse>({
    path: notificationsServiceRootPath,
  })

  return response.data
}

export async function enableNotificationSettingRequest(request: EnableUserNotificationRequest): Promise<void> {
  const response = await HttpPut<EnableUserNotificationRequest, void>({
    path: `${notificationsServiceRootPath}/enable`,
    body: request,
  })

  return response.data
}

export async function disableNotificationSettingRequest(request: EnableUserNotificationRequest): Promise<void> {
  const response = await HttpPut<DisableUserNotificationRequest, void>({
    path: `${notificationsServiceRootPath}/disable`,
    body: request,
  })

  return response.data
}

export async function getNotificationSettingRequest(
  context: QueryFunctionContext<[string, NotificationTypeEnum]>
): Promise<UserNotificationSettingResponse> {
  const [_, notificationType] = context.queryKey

  const response = await HttpGet<void, UserNotificationSettingResponse>({
    path: NotificationSettingsRequestPaths.getNotificationSetting(notificationType),
  })

  return response.data
}

export const NotificationSettingsRequestPaths = {
  getNotificationSetting: (type: NotificationTypeEnum) => `${notificationsServiceRootPath}/${type}`,
}
