import { useCallback, memo } from 'react'

import { Box, MenuItem } from '@mui/material'

import { useDispatch, useSelector } from '@tabeeb/store'

import { updateChatStatusFilter } from '@tabeeb/modules/sessionsPage/actions'
import { getChatStatusFilter } from '@tabeeb/modules/sessionsPage/selectors/filtration'
import { ChatStatus } from '@tabeeb/modules/sessionsPage/enums'

import { StyledSelect } from './ChatStatusFilter.styles'

const ChatStatusFilter = () => {
  const dispatch = useDispatch()

  const status = useSelector(getChatStatusFilter)

  const onChange = useCallback(
    (e) => {
      dispatch(updateChatStatusFilter(e.target.value))
    },
    [dispatch]
  )

  return (
    <Box display='flex' alignItems='center' height={44}>
      <StyledSelect value={status} fullWidth variant='outlined' onChange={onChange}>
        <MenuItem value={ChatStatus.All}>All</MenuItem>
        <MenuItem value={ChatStatus.UnreadOnly}>Unread only</MenuItem>
      </StyledSelect>
    </Box>
  )
}

export default memo(ChatStatusFilter)
