import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Tabs, Tab, withStyles } from '@material-ui/core'

import routes from '@tabeeb/routes'
import { getMainSelectedTextColor } from '@tabeeb/modules/appConfigState/selectors'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import CustomIFrameTab from '../CustomIFrame'
import Forms from '../Forms'
import Geosearch from '../Geosearch'
import Sessions from '../Sessions'
import Settings from '../Settings'
import Dashboard from '../Dashboard'
import Tenants from '../Tenants'
import ThreeDimensionalModels from '../ThreeDimensionalModels'
import Tracking from '../Tracking'
import TrainingMaterials from '../TrainingMaterials'
import BulkImportTab from '../BulkImportTab'

import styles from './styles'

const tabsList = [
  {
    Component: Sessions,
    Key: 1,
    AdditionalRoutes: [routes.allSessions, routes.publishedSessions, routes.deletedSessions],
  },
  {
    Component: Forms,
    Key: 2,
  },
  {
    Component: ThreeDimensionalModels,
    Key: 3,
  },
  {
    Component: Geosearch,
    Key: 4,
  },
  {
    Component: Tracking,
    Key: 5,
  },
  {
    Component: Dashboard,
    Key: 6,
    AdditionalRoutes: [
      routes.generatedReports,
      routes.powerBIReports,
      routes.powerBIReport,
      routes.powerBIReportProviders,
      routes.powerBIReportProvider,
      routes.tenantSessions,
      routes.allForms,
    ],
  },
  {
    Component: CustomIFrameTab,
    Key: 7,
  },
  {
    Component: Settings,
    Key: 8,
  },
  {
    Component: Tenants,
    Key: 9,
  },
  {
    Component: TrainingMaterials,
    Key: 10,
    AdditionalRoutes: [
      routes.trainingMaterialCreate,
      routes.trainingMaterialEdit,
      routes.trainingMaterialView,
      routes.contentTrainingMaterials,
      routes.contentTrainingMaterialView,
    ],
  },
  {
    Component: BulkImportTab,
    Key: 11,
  },
]

const NavbarTabs = ({ classes, isSessionPublishingEnabled, selectedTextColor, location }) => {
  const [selectedTab, setSelectedTab] = useState(false)

  useEffect(() => {
    const selectedTabIndex = tabsList.find(
      (item) => item.Component.to === location || item.AdditionalRoutes?.includes(location)
    )?.Key

    setSelectedTab(selectedTabIndex || false)
  }, [location, isSessionPublishingEnabled])

  return (
    <Tabs
      className={classes.root}
      value={selectedTab}
      variant='scrollable'
      scrollButtons='auto'
      TabIndicatorProps={{
        style: {
          backgroundColor: selectedTextColor,
        },
      }}
    >
      <Tab style={{ display: 'none' }} value={0} />
      {tabsList.map((item, index) => {
        const Item = item.Component
        return <Item key={item.Key} />
      })}
    </Tabs>
  )
}

NavbarTabs.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  selectedTextColor: PropTypes.string,
  location: PropTypes.string,
  isSessionPublishingEnabled: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    isSessionPublishingEnabled: appConfigStateSelectors.getIsSessionPublishingEnabled(state),
    textColor: appConfigStateSelectors.getMainTextColor(state),
    selectedTextColor: appConfigStateSelectors.getMainSelectedTextColor(state),
    location: state.router.location.pathname,
  }
}

export default withStyles(styles)(connect(mapStateToProps)(NavbarTabs))
