import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { put, takeLatest, select, all } from 'redux-saga/effects'
import moment from 'moment-timezone'
import * as rawActions from '../actions'
import { getCurrentForm, getSelectedGalleryItemId } from '../selectors'
import { calculateValue } from '../services/calculateDefaultValueOrCounterLimit'

function* setInitialValues() {
  const currentForm = yield select(getCurrentForm)
  const currentPageId = yield select(getSelectedGalleryItemId)
  const contentId = yield select(getContentId)
  const timestamp = yield select((state) => state.player.currentVideoTimestamp)

  const { ReattachedNumberOfTimes, ContentFormId, selectedTabs, Controls, answers } = currentForm

  const tabsIds = selectedTabs || []
  const actions = []

  Controls.forEach((control) => {
    const hasAnswer = answers?.some((answ) => answ.FormControlId === control.Id)

    if (Boolean(control.InitialValue) && !hasAnswer) {
      let answerValue
      const { InitialValue, IncrementValue, IncrementPeriod } = control

      if (ReattachedNumberOfTimes > 0 && IncrementValue && IncrementPeriod !== null) {
        answerValue = calculateValue(InitialValue, IncrementValue, IncrementPeriod, ReattachedNumberOfTimes)
      } else answerValue = InitialValue

      let answerInfo = {
        ContentId: contentId,
        ContentFormId,
        FormControlId: control.Id,
        PageId: currentPageId,
        Timestamp: timestamp,
        TimeZone: moment.tz.guess(),
      }

      if (answerValue) answerInfo = { ...answerInfo, Value: answerValue }
      if (tabsIds.length > 0) answerInfo = { ...answerInfo, FormTabsIds: tabsIds }

      actions.push(put(rawActions.sendFormAnswerRequest(answerInfo)))
    }
  })

  yield all(actions)
}

function* initialValues() {
  yield all([takeLatest(rawActions.setInitialValues, setInitialValues)])
}

export default initialValues
