import { NotificationTypeEnum } from '@tabeeb/contracts/microservices/swagger-notifications-service'
import { useQuery } from '@tanstack/react-query'
import { AccountQueryKeys, getNotificationSettingRequest } from '@tabeeb/modules/account/requests'

const useNotificationSetting = (notificationType: NotificationTypeEnum, enabled: boolean = true) => {
  const query = useQuery({
    queryKey: [AccountQueryKeys.notificationSettings, notificationType],
    queryFn: getNotificationSettingRequest,
    enabled,
  })

  return {
    notificationType,
    isEnabled: query.data?.isEnabled || false,
    isSuccess: query.isSuccess,
    isFetching: query.isFetching,
  }
}

export default useNotificationSetting
