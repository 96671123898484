import {
  Notifications,
  InsertChart,
  Apps,
  Home,
  Person,
  Book,
  AssignmentInd,
  Password,
  Security,
  Badge,
} from '@mui/icons-material'
import * as Yup from 'yup'

import { Errors } from '@tabeeb/shared/forms'
import { addQueriesToUrl } from '@tabeeb/shared/utils/helpers'
import domainService from '@tabeeb/services/domainService'

export const Tab = {
  ProfileDetails: 'profile-details',
  Address: 'address',
  Notifications: 'notifications',
  HealthData: 'health-data',
  Certificates: 'certificates',
  Qualifications: 'qualifications',
  MyAccount: 'my-account',
  ChangePassword: 'change-password',
  MultiFactorAuthentication: 'mfa',
  MyTenants: 'my-tenants',
}

export const getTabs = ({ showHealthData, showCertificates, showQualifications }) => [
  { id: Tab.ProfileDetails, label: 'Profile details', icon: <Person />, toDisplay: true },
  { id: Tab.Address, label: 'Address', icon: <Home />, toDisplay: true },
  { id: Tab.Notifications, label: 'Notifications', icon: <Notifications />, toDisplay: true },
  { id: Tab.HealthData, label: 'Health data', icon: <InsertChart />, toDisplay: showHealthData },
  { id: Tab.Certificates, label: 'Certificates', icon: <Book />, toDisplay: showCertificates },
  { id: Tab.Qualifications, label: 'Qualifications', icon: <Badge />, toDisplay: showQualifications },
  {
    id: Tab.MyAccount,
    label: 'My account',
    url: `${process.env.TABEEB_IDENTITY_URL}/Manage`,
    icon: <AssignmentInd />,
    toDisplay: true,
    displayDividerBefore: true,
  },
  {
    id: Tab.ChangePassword,
    label: 'Change password',
    url: `${process.env.TABEEB_IDENTITY_URL}/Manage/ChangePassword`,
    icon: <Password />,
    toDisplay: true,
  },
  {
    id: Tab.MultiFactorAuthentication,
    label: 'Multi-factor Authentication',
    url: `${process.env.TABEEB_IDENTITY_URL}/Manage/TwoFactorAuthentication`,
    icon: <Security />,
    toDisplay: true,
  },
  {
    id: Tab.MyTenants,
    label: 'My tenants',
    url: `${process.env.TABEEB_IDENTITY_URL}/Manage/Tenants`,
    icon: <Apps />,
    toDisplay: true,
  },
]

export const getSetupMfaISUrl = async () => {
  const setupMfaPathNameAndSearch = addQueriesToUrl(
    [
      {
        key: 'returnUrl',
        value: domainService.createOriginWithSubdomain(`${domainService.subdomain}.`),
      },
    ],
    '/Manage/TwoFactorAuthentication'
  )

  return `${process.env.TABEEB_IDENTITY_URL}${setupMfaPathNameAndSearch.pathname}?${setupMfaPathNameAndSearch.search}`
}

export const loginInitialValues = {
  Username: '',
  Password: '',
}

export const loginSchema = Yup.object().shape({
  Username: Yup.string().required(Errors.empty),
  Password: Yup.string().required(Errors.empty),
})

export const registerSchema = Yup.object().shape({
  FirstName: Yup.string().trim().max(35, 'Maximum length is 35 characters').required('Required'),
  LastName: Yup.string().trim().max(35, 'Maximum length is 35 characters').required('Required'),
})
