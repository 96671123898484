import { handleActions } from 'redux-actions'
import * as accountActions from '../actions'

const defaultState = {
  Email: '',
  FirstName: '',
  LastName: '',
  EmployeeId: '',
  Name: '',
  Institution: null,
  PhoneNumber: '',
  PhoneNumberConfirmed: false,
  DateOfBirth: '',
  IsCreatedByAdmin: false,
  Address: {},
  Gender: 0,
  SystemOfMeasure: 0,
  AvatarUrl: null,
  TenantRole: '',
  RoleName: '',
  TenantId: 0,
  HealthDataHistoryModels: null,
  TwoFactorEnabled: false,
  SsoOrMobileUser: false,
  Signature: null,
  Id: 0,
}

export default handleActions(
  {
    [accountActions.getMeSuccess](state, action) {
      return action.response.data
    },
    [accountActions.updateAccountSuccess](state, action) {
      return { ...state, ...action.payload }
    },
    [accountActions.setAvatarSuccess](state, action) {
      return { ...state, AvatarUrl: action.response.data }
    },
    [accountActions.removeAvatarSuccess](state) {
      return { ...state, AvatarUrl: '' }
    },
    [accountActions.updateUserSignature.success](state, action) {
      return { ...state, Signature: action.response.data }
    },
    [accountActions.onLogout](state) {
      return { ...defaultState }
    },
  },
  defaultState
)
