import { Stack, Box } from '@mui/material'
import { Body1, Body2, Help, Masonry, Section, Switch } from '@tabeeb/uikit'
import { useNotificationSettings } from '@tabeeb/modules/account/hooks'
import NotificationSettingsSkeleton from './NotificationSettingsSkeleton'

const NotificationSettings = () => {
  const { settingsInProcessing, notificationsCategories, isLoading, isError, isFetching, onNotificationSettingChange } =
    useNotificationSettings()

  if (isLoading) {
    return <NotificationSettingsSkeleton />
  }

  if (isError) {
    return <Body1 sx={{ textAlign: 'center', width: '100%' }}>Error loading notification settings</Body1>
  }

  return (
    <Masonry columns={2} spacing={2}>
      {notificationsCategories.map((category) => (
        <Section key={category.name} title={category.name}>
          <Stack spacing={2.5}>
            {category.notificationSettings.map((notificationSetting) => (
              <Box key={notificationSetting} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display='flex' mr={2.5}>
                  <Body2 mr={1}>{notificationSetting.name}</Body2>
                  {notificationSetting.description && <Help text={notificationSetting.description} />}
                </Box>
                <Switch
                  disabled={settingsInProcessing.includes(notificationSetting.type) || isFetching}
                  checked={notificationSetting.isEnabled}
                  onChange={(_event, checked) => onNotificationSettingChange(notificationSetting.type, checked)}
                  size='large'
                />
              </Box>
            ))}
          </Stack>
        </Section>
      ))}
    </Masonry>
  )
}

export default NotificationSettings
