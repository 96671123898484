import { useEffect } from 'react'
import PropTypes from 'prop-types'

import routes from '@tabeeb/routes'
import { useSelector } from '@tabeeb/store'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import { useUnreadChatsCount } from '@tabeeb/modules/chat/hooks'

import { MarkEmailUnreadOutlined } from '@mui/icons-material'

import NavbarTab from '../NavbarTab'

const Sessions = (props) => {
  const sessionsNavbarTitle = useSelector(appConfigStateSelectors.getSessionTitle)
  const enabled = useSelector(
    (state) =>
      appConfigStateSelectors.getIsChatEnabled(state) &&
      appConfigStateSelectors.getIsUnreadSessionChatsIndicatorEnabled(state)
  )

  const { count: unreadChatsCount, invalidate: onRefresh } = useUnreadChatsCount(enabled)
  const { selected } = props

  useEffect(() => {
    if (selected && enabled) {
      onRefresh()
    }
  }, [enabled, onRefresh, selected])

  const tabProps = {
    item: {
      label: `${sessionsNavbarTitle}`,
      to: routes.home,
      icon:
        unreadChatsCount > 0 ? (
          <MarkEmailUnreadOutlined onClick={onRefresh} sx={{ ml: 1 }} color='primary' fontSize='small' />
        ) : null,
    },
    ...props,
  }

  return <NavbarTab {...tabProps} />
}

Sessions.to = routes.home

Sessions.propTypes = {
  selected: PropTypes.bool,
}

export default Sessions
