import { combineActions, handleActions } from 'redux-actions'
import { onJitsiDeinitialized, onJitsiInitialized } from '@tabeeb/modules/presentation/actions/conference'
import { resetContentState } from '@tabeeb/shared/content/actions'
import { signalrConstants, signalrEvents } from '@tabeeb/modules/signalr'
import { notifyStartCall } from '@tabeeb/modules/presentation/actions/connection'

const initialState = false

export default handleActions(
  {
    [onJitsiInitialized]() {
      return true
    },
    [combineActions(
      onJitsiDeinitialized,
      resetContentState,
      notifyStartCall,
      signalrEvents[signalrConstants.tabeebHubName].onCallEnded
    )]() {
      return initialState
    },
  },
  initialState
)
