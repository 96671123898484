const uniqueAIObjectConnectionRequests = {
  addUniqueAiObjectConnectionRequest: (data) => ({
    url: `api/ai/uniqueObjects/connection`,
    method: 'post',
    data,
  }),
  autofillPageUniqueAiObjectsRequest: (data) => ({
    url: `api/ai/uniqueObjects/autofill`,
    method: 'put',
    data,
  }),
  deleteUniqueAiObjectConnectionRequest: ({ uniqueAIObjectConnectionId }) => ({
    url: `api/ai/uniqueObjects/connection/${uniqueAIObjectConnectionId}`,
    method: 'delete',
  }),
  getUniqueAiObjectsByIdsRequest: ({ uniqueAIObjectIds }) => ({
    url: `api/ai/uniqueObjects/get-by-ids`,
    method: 'post',
    data: { uniqueAIObjectIds },
  }),
}

export default uniqueAIObjectConnectionRequests
