import { handleActions } from 'redux-actions'

import { updateChatStatusFilter, resetFilters } from '../../actions'
import { ChatStatus } from '../../enums'

const defaultState = ChatStatus.All

export default handleActions(
  {
    [updateChatStatusFilter.toString()](state, action) {
      return action.payload
    },
    [resetFilters.toString()](state, action) {
      return defaultState
    },
  },
  defaultState
)
