import { createSelector } from 'reselect'
import { hexToRgb } from '@material-ui/core'
import { MainScreenContentType, SessionPermission } from '@tabeeb/enums'
import { permissionsSelectors } from '@tabeeb/modules/permissions'
import { isSupportAnnotations } from '@tabeeb/services/pageService'
import { getIsGridViewActive } from '@tabeeb/modules/gridView/selectors'
import { trackingSelectors } from '@tabeeb/modules/tracking'
import { getSelectedGalleryItemType, getSelectedGalleryItemSupportsRotation } from '@tabeeb/modules/gallery/selectors'
import { selectIsPointCloudGeoSearchOpen } from '@tabeeb/modules/pointCloudGeoSearch/selectors'
import { getShowMenuAI } from '@tabeeb/modules/appConfigState/selectors'

export const getHideAnnotations = (state) => state.playerToolbar.hideAnnotations
export const getWidth = (state) => state.playerToolbar.drawing.drawingState.width
export const getTransparency = (state) => state.playerToolbar.drawing.drawingState.transparency
export const getFontSize = (state) => Math.round(getWidth(state) * 5 + 6)
export const getIsDrawingEnabled = (state) => state.playerToolbar.drawing.drawingState.isDrawing
export const getIsSelectionEnabled = (state) => state.playerToolbar.drawing.drawingState.isSelecting
export const getIsSnippingToolEnabled = (state) => state.playerToolbar.snippingToolEnabled
export const getDrawingPreset = (state) => state.playerToolbar.drawing.drawingState.preset
export const getDrawingScale = (state) => state.playerToolbar.drawing.drawingState.scale
export const getDrawingAnnotationType = (state) => state.playerToolbar.drawing.drawingState.type
export const getScale = (state) => state.playerToolbar.scale

export const getIsButtonSelected = (state, button) => state.playerToolbar.drawing.buttonState === button

export const getIsAiEnabled = getShowMenuAI
export const getIsLargeVideoUser = (state) => state.presentation.tracks.largeVideoUser
export const getHasAiAnnotationPermission = (state) =>
  permissionsSelectors.hasSessionPermission(state, SessionPermission.AddAiAnnotations)
export const getHasAnnotationPermission = (state) =>
  permissionsSelectors.hasSessionPermission(state, SessionPermission.AddAnnotations)
export const getHasRotatePermission = (state) =>
  permissionsSelectors.hasSessionPermission(state, SessionPermission.RotateImages)

export const getSelectedColor = (state) => {
  let { color } = state.playerToolbar.drawing.drawingState
  const { transparency } = state.playerToolbar.drawing.drawingState
  if (transparency === 1.0) {
    return color
  }

  if (color.startsWith('#')) {
    color = hexToRgb(color)
  }

  const rgb = color.match(/\d+/g)
  return `rgba(${rgb[0]},${rgb[1]}, ${rgb[2]}, ${transparency})`
}

export const getIsAiFlagButtonVisible = createSelector(
  [getIsAiEnabled, getHasAiAnnotationPermission, getIsLargeVideoUser],
  (isAiEnabled, hasPermission, largeVideoUser) => {
    return isAiEnabled && hasPermission && !largeVideoUser
  }
)

export const getCanAddAnnotations = createSelector(
  [getHasAnnotationPermission, getIsLargeVideoUser],
  (hasPermission, largeVideoUser) => {
    return hasPermission && !largeVideoUser
  }
)

export const getCanAddAnnotationsOnSelectedPage = createSelector(
  [getCanAddAnnotations, getSelectedGalleryItemType],
  (canAddAnnotations, contentType) => {
    return canAddAnnotations && isSupportAnnotations(contentType)
  }
)

export const getIsRotationInProgress = (state) => !state.playerToolbar.rotatePage.isEnableButton

export const getCanRotateSelectedPage = createSelector(
  [getHasRotatePermission, getSelectedGalleryItemSupportsRotation],
  (hasPermission, supportsRotation) => {
    return hasPermission && supportsRotation
  }
)

export const getMainScreenContentType = createSelector(
  [getIsLargeVideoUser, getIsGridViewActive, trackingSelectors.getIsOpen, selectIsPointCloudGeoSearchOpen],
  (isLargeVideo, isGridViewActive, isTrackingOpen, isPointCloudGeoSearchOpen) => {
    if (isLargeVideo || isGridViewActive) {
      return MainScreenContentType.Call
    }

    if (isTrackingOpen) {
      return MainScreenContentType.Tracking
    }

    if (isPointCloudGeoSearchOpen) {
      return MainScreenContentType.PointCloudGeoSearch
    }

    return MainScreenContentType.Gallery
  }
)
