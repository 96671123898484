import { Tooltip } from '@tabeeb/uikit'
import { sessionTitleFormatter } from '@tabeeb/modules/shared/utils/text'

import { BubbleContainer, UnreadMessagesIcon } from './UnreadMessagesBubble.styles'

const UnreadMessagesBubble = () => {
  return (
    <Tooltip text={sessionTitleFormatter.format('Session has unread message(s)')}>
      <BubbleContainer>
        <UnreadMessagesIcon fontSize='small' />
      </BubbleContainer>
    </Tooltip>
  )
}

export default UnreadMessagesBubble
