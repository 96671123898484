import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ChatOutlined } from '@material-ui/icons'
import { Switch } from '@material-ui/core'

import { getIsMuteChatNotificationsVisible } from '@tabeeb/modules/navbar/selectors'
import { usersActions, usersSelectors } from '@tabeeb/modules/../users'
import { contentStateSelectors } from '@tabeeb/shared/content'

import { NotificationTypeEnum } from '@tabeeb/contracts/microservices/swagger-notifications-service'
import { useNotificationSetting } from '@tabeeb/modules/account/hooks'
import SettingsField from '../SettingsField'

const MuteChatNotifications = () => {
  const dispatch = useDispatch()

  const contentId = useSelector(contentStateSelectors.getContentId)
  const user = useSelector(usersSelectors.getCurrentUser)
  const isVisible = useSelector(getIsMuteChatNotificationsVisible)

  const { isEnabled, isFetching, isSuccess } = useNotificationSetting(NotificationTypeEnum.ChatMessage, isVisible)

  const disabled = !isEnabled || isFetching
  const checked = user?.chatNotificationsMuted || (isSuccess && (!isEnabled || user?.chatNotificationsMuted)) || false

  const onChange = (_, value) => {
    dispatch(usersActions.toggleChatNotificationsMuteRequest({ contentId, userId: user.id, value }))
  }

  if (!isVisible) {
    return null
  }

  return (
    <SettingsField icon={ChatOutlined} label='Mute chat notifications'>
      <Switch
        style={{ pointerEvents: 'unset' }}
        color='primary'
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        title={checked ? 'Unmute chat notifications' : 'Mute chat notifications'}
      />
    </SettingsField>
  )
}

export default memo(MuteChatNotifications)
