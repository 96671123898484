import { createAction } from 'redux-actions'

export const initRoom = createAction('INIT_ROOM')
export const setJitsiRoom = createAction('SET_JITSI_ROOM')
export const disposeConference = createAction('DISPOSE_CONFERENCE')
export const leaveRoom = createAction('LEAVE_ROOM')
export const onTrackAdded = createAction('ON_TRACK_ADDED')
export const onTrackRemoved = createAction('ON_TRACK_REMOVED')
export const onKicked = createAction('ON_KICKED')
export const onUserLeft = createAction('ON_USER_LEFT')
export const onUserJoined = createAction('ON_USER_JOINED')
export const bindRoomEvents = createAction('BIND_ROOM_EVENTS')
export const unBindRoomEvents = createAction('UNBIND_ROOM_EVENTS')
export const onConferenceJoined = createAction('ON_CONFERENCE_JOINED')
export const onConferenceFailed = createAction('ON_CONFERENCE_FAILED')
export const onTracksInitializationFailed = createAction('ON_TRACKS_INITIALIZATION_ERROR')
export const trackAudioLevelChanged = createAction('TRACK_AUDIO_LEVEL_CHANGED')
export const showSpeaker = createAction('SHOW_SPEAKER')

export const setParticipantIdForUser = createAction('SET_PARTICIPANT_ID_FOR_USER')
export const openCallPreviewDialog = createAction('OPEN_CALL_PREVIEW_DIALOG')
export const closeCallPreviewDialog = createAction('CLOSE_CALL_PREVIEW_DIALOG')

export const resetCallLeavingInProgress = createAction('RESET_CALL_LEAVING_IN_PROGRESS')
export const onJitsiInitialized = createAction('ON_JITSI_INITIALIZED')
export const onJitsiDeinitialized = createAction('ON_JITSI_DEINITIALIZED')

export function resetFlags() {
  return (dispatch, getState) => {
    dispatch(disposeConference())
  }
}
