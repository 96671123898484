import { put, takeLatest, select, all } from 'redux-saga/effects'

import { onSetAppConfigState } from '@tabeeb/modules/appConfigState/actions'
import { onAddErrorNotification, onAddInfoNotification } from '@tabeeb/modules/notification/actions'
import { getConfig } from '@tabeeb/modules/appConfigState/selectors'

import { queryClient } from '@tabeeb/services/queryClient'
import { AccountQueryKeys } from '@tabeeb/modules/account/requests'
import { setConfigForUpdate, updateConfig } from '../actions'
import { TenantConfig } from '../types'

function* onUpdateConfigSuccess({ response }: ReturnType<typeof updateConfig.success>) {
  const updatedConfig = response.data
  const appConfigState: TenantConfig = yield select(getConfig)

  if (appConfigState.subdomain === updatedConfig.subdomain) {
    yield put(onSetAppConfigState({ ...appConfigState, ...updatedConfig }))
  }

  yield put(setConfigForUpdate(updatedConfig))
  yield put(onAddInfoNotification({ message: 'Config updated' }))
  yield queryClient.invalidateQueries({ queryKey: [AccountQueryKeys.notificationSettings] })
}

function* onUpdateConfigFailed() {
  yield put(onAddErrorNotification({ message: 'There was an error updating the config' }))
}

function* updateConfigSagas() {
  yield all([
    takeLatest(updateConfig.success, onUpdateConfigSuccess),
    takeLatest(updateConfig.failed, onUpdateConfigFailed),
  ])
}

export default updateConfigSagas
