import { createSelector } from 'reselect'

import { hasSessionPermission } from '@tabeeb/modules/permissions/selectors'
import { getCurrentUserReview } from '@tabeeb/modules/contentReviews/selectors'
import { getIsCallStarted } from '@tabeeb/modules/presentation/selectors'
import {
  getIsDeviceSwitchingEnabled,
  getShowCertificates,
  getShowHealthData,
  getShowMenuAI,
} from '@tabeeb/modules/appConfigState/selectors'
import { getHasShowHealthDataPermission } from '@tabeeb/modules/healthData/selectors'
import { getCurrentUserId, getRole } from '@tabeeb/modules/account/selectors'
import { getIsCurrentUserPresenter, getPresenterId } from '@tabeeb/shared/content/selectors'
import { DeviceType, SessionPermission, UserRole } from '@tabeeb/enums'

import { ZOOM_MIN } from '../common/webZoom'
import { tenantsRolesToAllowEditPatient } from '../constants'

export const getUsersList = (state) => state.users.users
export const getOwnerId = (state) => state.contentState.ownerId
export const getIsAiEnabled = getShowMenuAI
export const getCurrentForm = (state) => state.forms.currentForm
export const getReviewsList = (state) => state.contentReviews.reviewsList

export const getHasChangeUserRolePermission = (state) => hasSessionPermission(state, SessionPermission.ChangeUserRole)
export const getHasChangeAnswerStatusPermission = (state) =>
  hasSessionPermission(state, SessionPermission.RejectApprove)
export const getHasRemoveUserPermission = (state) => hasSessionPermission(state, SessionPermission.RemoveUsers)

export const getIsCurrentUserReviewer = createSelector([getUsersList, getCurrentUserId], (usersList, currentUserId) => {
  const currentUser = usersList.find((user) => user.id === currentUserId)
  return currentUser?.role === UserRole.Reviewer
})

export const getIsCurrentUserViewer = createSelector([getUsersList, getCurrentUserId], (usersList, currentUserId) => {
  const currentUser = usersList.find((user) => user.id === currentUserId)
  if (!currentUser) return false
  return currentUser.role === UserRole.Viewer
})

export const getIsCurrentUserLabeler = createSelector([getUsersList, getCurrentUserId], (usersList, currentUserId) => {
  const currentUser = usersList.find((user) => user.id === currentUserId)
  if (!currentUser) return false
  return currentUser.role === UserRole.Labeler
})

export const getIsCurrentUserApprover = createSelector([getUsersList, getCurrentUserId], (usersList, currentUserId) => {
  const currentUser = usersList.find((user) => user.id === currentUserId)
  if (!currentUser) return false
  return currentUser.role === UserRole.Approver
})

export const getIsCurrentUserOwner = createSelector([getOwnerId, getCurrentUserId], (ownerId, currentUserId) => {
  return ownerId === currentUserId
})

export const getCurrentUser = createSelector([getUsersList, getCurrentUserId], (usersList, userId) => {
  return usersList.find((user) => user.id === userId)
})

export const getCurrentUserRole = createSelector(getCurrentUser, (user) => user.TenantRole)

export const getUserById = createSelector([getUsersList, (_, { Id }) => Id], (usersList, userId) => {
  return usersList.find((user) => user.id === userId)
})

export const getUserWebZoomValueById = createSelector([(state, id) => getUserById(state, { Id: id })], (user) => {
  return user?.webZoomValue || ZOOM_MIN
})

// TODO: move to the backend
export const getUsersListForCurrentUser = createSelector(
  [getCurrentUserId, getUsersList, getIsAiEnabled, getCurrentUserReview],
  (currentUserId, usersList, isAiEnabled, review) => {
    const currentUser = usersList.find((user) => user.id === currentUserId)
    let filteredList

    if (currentUser) {
      const isOwner = currentUser.role === UserRole.Owner
      const isReviewer = currentUser.role === UserRole.Reviewer

      if (isOwner) filteredList = usersList
      else if (isReviewer) {
        const visibleUsers = review ? [...review.ReviewersIds, review.ReviewerId] : []

        filteredList = usersList.filter((u) => u.role === UserRole.Owner || visibleUsers.includes(u.id))
      } else filteredList = usersList.filter((u) => u.role !== UserRole.Reviewer)
    } else filteredList = usersList

    if (!isAiEnabled) filteredList = filteredList.filter((user) => user.role !== UserRole.AIUser)

    return filteredList
  }
)

export const getIsMakePresenterButtonVisible = createSelector(
  [getIsCurrentUserPresenter, (_, { user }) => user],
  (isPresenter, user) => {
    if (user.isAccountDeleted) {
      return false
    }

    const isMobileUser =
      user.deviceType === DeviceType.androidDevice ||
      user.deviceType === DeviceType.appleDevice ||
      user.deviceType === DeviceType.androidBrowser ||
      user.deviceType === DeviceType.iosBrowser
    const isMakePresenterVisible = isPresenter && user.isOnline && !isMobileUser && user.role !== UserRole.Viewer

    return isMakePresenterVisible
  }
)

export const getIsSwitchRoleButtonsVisible = createSelector(
  [getPresenterId, getHasChangeUserRolePermission, (_, { user }) => user],
  (presenterId, hasChangeUserRolePermission, user) => {
    if (user.isAccountDeleted) {
      return false
    }

    if (![UserRole.User, UserRole.Viewer, UserRole.SessionGuest].includes(user.role)) {
      return false
    }

    return hasChangeUserRolePermission && presenterId !== user.id
  }
)

export const getIsShowHealthDataButtonVisible = createSelector(
  [getHasShowHealthDataPermission, (_, { user }) => user],
  (hasShowHealthDataPermission, user) => {
    if (user.isAccountDeleted) {
      return false
    }

    if (user.role !== UserRole.SessionGuest) {
      return false
    }

    return hasShowHealthDataPermission
  }
)

export const getIsShowCertificatesButtonVisible = createSelector(
  [getShowCertificates, (_, { user }) => user],
  (showCertificates, user) => {
    return showCertificates
  }
)

export const makeGetIsUserOptionsButtonVisible = () =>
  createSelector(
    [
      getIsCurrentUserPresenter,
      getIsCurrentUserOwner,
      getCurrentUserId,
      getIsShowHealthDataButtonVisible,
      getIsShowCertificatesButtonVisible,
      (_, { user }) => user,
    ],
    (isPresenter, isOwner, currentUserId, isShowHealthDataButtonVisible, showCertificates, user) => {
      const isUserOptionsButtonVisible =
        user.id !== currentUserId &&
        ((user.role !== UserRole.Reviewer && (isOwner || (isPresenter && user.isOnline))) ||
          isShowHealthDataButtonVisible ||
          showCertificates)

      return isUserOptionsButtonVisible
    }
  )

export const makeGetIsUserDeviceOptionsButtonVisible = () =>
  createSelector(
    [getIsCallStarted, getCurrentUserId, getIsDeviceSwitchingEnabled, (_, { user }) => user],
    (isCallStarted, currentUserId, isDeviceSwitchingEnabled, user) => {
      const isUserDeviceOptionsButtonVisible =
        user.id === currentUserId && user.role !== UserRole.Reviewer && isCallStarted && isDeviceSwitchingEnabled

      return isUserDeviceOptionsButtonVisible
    }
  )

export const makeGetIsCertificatesOptionsVisible = () =>
  createSelector(
    [getShowCertificates, getCurrentUserId, (_, { user }) => user],
    (showCertificates, currentUserId, user) => {
      const isVisible = user.id === currentUserId && showCertificates

      return isVisible
    }
  )

export const makeGetIsHealthInfoOptionsVisible = () =>
  createSelector(
    [getShowHealthData, getCurrentUserId, (_, { user }) => user],
    (showHealthData, currentUserId, user) => {
      const isHealthInfoOptionsVisible =
        user.id === currentUserId && showHealthData && user.role === UserRole.SessionGuest

      return isHealthInfoOptionsVisible
    }
  )

export const getIsUserOptionsButtonVisible = makeGetIsUserOptionsButtonVisible()

export const makeGetIsKickUserButtonVisible = () =>
  createSelector([getHasRemoveUserPermission, (_, { user }) => user], (hasPermission, user) => {
    return hasPermission && user.role !== UserRole.Reviewer
  })

export const getIsEditPatientDataVisible = createSelector(
  [getCurrentUser, getRole, (_, { contentId }) => contentId],
  (currentSessionUser, currentUserRole, contentId) => {
    let isEditPatientDataVisible = false

    if (contentId !== 0) {
      isEditPatientDataVisible =
        currentSessionUser?.role === UserRole.Owner || currentSessionUser?.role === UserRole.SessionGuest
    } else {
      isEditPatientDataVisible = tenantsRolesToAllowEditPatient.includes(currentUserRole)
    }

    return isEditPatientDataVisible
  }
)

export const getPresenterEmail = createSelector([getUsersList, getPresenterId], (users, presenterId) => {
  return users?.find((user) => user?.id === presenterId)?.email
})
