import { createAction } from 'redux-actions'

import { createApiActions } from '@tabeeb/modules/shared/utils/actions'
import { TenantConfig } from '@tabeeb/contracts/microservices/swagger-tenant-service'

export const getConfig = createApiActions<{ subdomain: string }, TenantConfig>('GET_CONFIG')

export const getVideoBridgeConfig = createApiActions<void, Object>('GET_VIDEO_BRIDGE_CONFIG')

export const onSetAppConfigState = createAction<TenantConfig>('SET_APP_CONFIG_STATE')
