const styles = (theme) => ({
  content: {
    margin: `${theme.spacing() * 2}px auto`,
    padding: theme.spacing() * 2,
    height: 'max-content',
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
  },
})

export default styles
