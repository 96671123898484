import { createSelector } from 'reselect'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'

import { TenantPermission } from '@tabeeb/enums'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { getIsChatEnabled } from '@tabeeb/modules/appConfigState/selectors'

import { ChatStatus, Filter } from '../enums'
import { getChatStatusFilter, getIsFilterEnabled } from './filtration'
import { isNeedToApprove } from '../services/sessionApproval'

export const getContentsList = (state) => state.sessionsPage.sessionsList
export const getFoldersList = (state) => state.sessionsPage.foldersList
export const getCurrentFolder = (state) => state.sessionsPage.currentFolder
export const getCurrentFolderId = (state) => state.sessionsPage.currentFolderId
export const getIsTrashOpen = (state) => state.sessionsPage.isTrashBinOpen
export const getIsNeedPermissionToActivate = (state) => state.sessionsPage.isNeedPermissionToActivate
export const getIsSessionCreationInProgress = (state) => state.sessionsPage.isSessionCreationInProgress

export const getBillingSettings = (state) => state.sessionState.billingSettings
export const getSessionApprovalSettings = (state) => state.sessionState.sessionApprovalSettings
export const getIsDataLoading = (state) => state.sessionsPage.isDataLoading

export const getLayout = (state) => state.sessionsPage.layout
export const getMode = (state) => state.sessionsPage.mode

export const getIsContentOwner = createSelector(
  [getCurrentUserId, (_, session) => session],
  (currentUserId, session) => {
    return session && session.Owner ? session.Owner.Id === currentUserId : false
  }
)

export const getIsNeedAdminPermission = createSelector(
  [
    getBillingSettings,
    getSessionApprovalSettings,
    (state) => hasTenantPermission(state, TenantPermission.ContentApproval),
    (_, { session }) => session,
  ],
  (billingSettings, sessionApprovalSettings, hasApprovalPermission, session) => {
    let needsAdminApproval = false

    if (billingSettings.IsBillingEnabled) {
      needsAdminApproval = isNeedToApprove(session, sessionApprovalSettings)
      return !hasApprovalPermission && needsAdminApproval
    }

    return needsAdminApproval
  }
)

export const getTotalContentsCount = createSelector([getContentsList], (contents) => {
  const totalContentsCount = contents.length > 0 ? contents[0].TotalCount : 0
  return totalContentsCount
})

export const getSelectedSessionsList = (state) => state.sessionsPage.selectedSessionsList

export const getSelectedSessionsIds = createSelector([getSelectedSessionsList], (sessions) => {
  return sessions.map((session) => session.Id)
})

export const getSelectedSessionsCount = (state) => getSelectedSessionsList(state).length

export const getFeatures = (state) => state.sessionsPage.configuration.features
export const getIsFeatureEnabled = createSelector([getFeatures, (_, feature) => feature], (features, feature) => {
  return features.includes(feature)
})

export const getIsFoldersListHidden = (state) =>
  getIsChatEnabled(state) &&
  getIsFilterEnabled(state, Filter.ByChatStatus) &&
  getChatStatusFilter(state) === ChatStatus.UnreadOnly
