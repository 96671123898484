import { createSelector } from 'reselect'

import { HealthDataTypes, IOTDeviceUnitType, SessionPermission } from '@tabeeb/enums'
import { getMe } from '@tabeeb/modules/account/selectors'
import { getShowHealthData } from '@tabeeb/modules/appConfigState/selectors'
import { hasSessionPermission } from '@tabeeb/modules/permissions/selectors'
import {
  getCmFromInches,
  getFootsFromCm,
  getFootsFromInches,
  getFractionalPartOfFootsFromInches,
  getFractionalPartOfFootsInInchesFromCm,
  getKgFromLbs,
  getLbsFromKg,
} from '@tabeeb/modules/healthData/helper/measurementConversion'

export const getChartsHealthData = (state) => state.healthData.chartsHealthData
export const getChartsDataTypesAndIntervals = createSelector([getChartsHealthData], (chartsHealthData) =>
  chartsHealthData.map((e) => ({ dataType: e.dataType, interval: e.interval }))
)
export const getChartsTypesAndNames = createSelector([getChartsHealthData], (chartsHealthData) => {
  return chartsHealthData.map((e) => {
    return { dataType: e.dataType, name: e.name }
  })
})

export const getChartsHealthDataOfPatient = (state) => state.healthData.chartsHealthDataOfPatient
export const getChartsDataTypesAndIntervalsOfPatient = createSelector(
  [getChartsHealthDataOfPatient],
  (chartsHealthData) => chartsHealthData.map((e) => ({ dataType: e.dataType, interval: e.interval }))
)
export const getChartsTypesAndNamesOfPatient = createSelector([getChartsHealthDataOfPatient], (chartsHealthData) => {
  return chartsHealthData.map((e) => {
    return { dataType: e.dataType, name: e.name }
  })
})

export const getHealthData = (state) => state.healthData.currentUserHealthData

export const getLife365ListOfPrograms = (state) => state.healthData.life365Programs
export const getHealthDevices = (state) => state.healthData.healthDevices
export const getAssignedLife365DeviceStatus = (state) => state.healthData.life365DeviceStatus

export const getCurrentUserWithHealthDataUpdateProfile = createSelector([getMe, getHealthData], (me, healthData) => {
  const { Address, Email, FirstName, LastName, Institution, PhoneNumber, DateOfBirth, Gender, SystemOfMeasure } = me
  const heightHealthData = healthData.find((data) => data.MeasureId === HealthDataTypes.Height)
  const weightHealthData = healthData.find((data) => data.MeasureId === HealthDataTypes.Weight)

  return {
    Address,
    Email,
    FirstName,
    LastName,
    Institution,
    PhoneNumber,
    DateOfBirth,
    Gender,
    SystemOfMeasure,
    HeightCm: Boolean(heightHealthData)
      ? heightHealthData.MeasureUnitId === IOTDeviceUnitType.Centimeter
        ? heightHealthData?.Value.toFixed(1)
        : getCmFromInches(heightHealthData?.Value).toFixed(1)
      : null,
    HeightFt: Boolean(heightHealthData)
      ? heightHealthData.MeasureUnitId === IOTDeviceUnitType.Inch
        ? getFootsFromInches(heightHealthData?.Value)
        : getFootsFromCm(heightHealthData?.Value)
      : null,
    HeightIn: Boolean(heightHealthData)
      ? heightHealthData.MeasureUnitId === IOTDeviceUnitType.Inch
        ? getFractionalPartOfFootsFromInches(heightHealthData?.Value).toFixed(0)
        : getFractionalPartOfFootsInInchesFromCm(heightHealthData?.Value).toFixed(0)
      : null,
    WeightKg: Boolean(weightHealthData)
      ? weightHealthData.MeasureUnitId === IOTDeviceUnitType.Kilogram
        ? weightHealthData?.Value.toFixed(1)
        : getKgFromLbs(weightHealthData?.Value).toFixed(1)
      : null,
    WeightLbs: Boolean(weightHealthData)
      ? weightHealthData.MeasureUnitId === IOTDeviceUnitType.Pound
        ? weightHealthData?.Value.toFixed(1)
        : getLbsFromKg(weightHealthData?.Value).toFixed(1)
      : null,
  }
})
export const getHasShowHealthDataPermission = createSelector(
  [getShowHealthData, (state) => hasSessionPermission(state, SessionPermission.HealthDataView)],
  (showHealthData, hasShowHealthDataPermission) => {
    return showHealthData && hasShowHealthDataPermission
  }
)
export const getViewedPatient = (state) => state.healthData.viewedPatient
export const getViewedPatientId = createSelector([getViewedPatient], (patient) => patient.Id)
export const getDevicesOfPatient = (state) => state.healthData.devicesOfPatient

export const getUserHealthDataSetting = (state) => state.healthData.healthInfoSettings.currentUser
export const getUserPatientHealthInfoSetting = (state) => state.healthData.healthInfoSettings.patientUser
