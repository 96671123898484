import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PublicOutlined } from '@material-ui/icons'
import { FormControl, MenuItem, Select } from '@material-ui/core'

import { getVideoBridgeRegions } from '@tabeeb/modules/appConfigState/selectors'

import * as navbarActions from '@tabeeb/modules/navbar/actions'
import * as navbarSelectors from '@tabeeb/modules/navbar/selectors'
import * as presentationSelectors from '@tabeeb/modules/presentation/selectors'
import * as contentStateSelectors from '@tabeeb/modules/shared/content/selectors'

import SettingsField from '../SettingsField'

const VideoBridgeRegions = () => {
  const dispatch = useDispatch()

  const contentId = useSelector(contentStateSelectors.getContentId)
  const isCallStarted = useSelector(presentationSelectors.getIsCallStarted)
  const isVisible = useSelector(navbarSelectors.getIsVideobridgeSelectorVisible)

  const regions = useSelector(getVideoBridgeRegions)
  const currentVideoBridge = useSelector((state) => state.topNavBar.currentVideoBridge)

  const onChangeVideoBridgeRegion = (event) => {
    const selectedRegion = event.target.value
    if (selectedRegion.url === currentVideoBridge.url) {
      return
    }

    dispatch(navbarActions.setSelectedVideoBridge(selectedRegion))
    dispatch(
      navbarActions.setVideoBridgeRequest({
        ContentId: contentId,
        VideobridgeUrl: selectedRegion.url,
      })
    )
  }

  if (!isVisible) {
    return null
  }

  return (
    <SettingsField icon={PublicOutlined} label='Video chat region'>
      <FormControl size='small'>
        <Select
          disabled={isCallStarted}
          value={currentVideoBridge}
          onChange={onChangeVideoBridgeRegion}
          title={currentVideoBridge.name}
          disableUnderline
        >
          {regions.map((region, index) => {
            return (
              <MenuItem key={index} value={region}>
                {region.name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </SettingsField>
  )
}

export default memo(VideoBridgeRegions)
