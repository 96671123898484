function _getConfigRequest({ subdomain }) {
  return {
    url: `tenant-service/api/config/${subdomain}`,
    method: 'get',
  }
}

const configRequests = {
  getConfigRequest: (data) => _getConfigRequest(data),
  getConfigForUpdateRequest: (data) => _getConfigRequest(data),
  updateConfigRequest: ({ subdomain, config }) => ({
    url: `tenant-service/api/config/${subdomain}`,
    method: 'put',
    data: config,
  }),
  getFtpServerSettingsRequest: (subdomain) => ({
    url: `tenant-service/api/config/ftpServer/${subdomain}`,
    method: 'get',
  }),
  updateFtpServerSettingsRequest: ({ subdomain, settings }) => ({
    url: `tenant-service/api/config/ftpServer/${subdomain}`,
    method: 'put',
    data: settings,
  }),
  getVideoBridgeConfigRequest: () => ({
    url: `api/config/getvideobridgeconfig`,
    method: 'get',
  }),
  getTenantsListRequest: (subdomain) => ({
    url: `tenant-service/api/tenants/getall/${subdomain}`,
    method: 'get',
  }),
  regenerateFtpPasswordRequest: () => ({
    url: `tenant-service/api/config/ftpServer/regeneratePassword`,
    method: 'put',
  }),
}

export default configRequests
