import axios, { AxiosResponse } from 'axios'
import oidcUserManager from '@tabeeb/services/oidcUserManager'
import domainService from '@tabeeb/services/domainService'
import { HttpDeleteOptions, HttpGetOptions, HttpPostOptions, HttpPutOptions } from '@tabeeb/shared/utils/API/types'

const apiRootUrl = `${process.env.TABEEB_API_URL}/`

const getAccessTokenAsync = async (): Promise<string> => {
  const currentUser = await oidcUserManager.getUser()

  if (!currentUser) {
    throw new Error('User is not authenticated')
  }

  return currentUser.access_token
}

const getRequiredHeadersAsync = async () => {
  return {
    Authorization: `Bearer ${await getAccessTokenAsync()}`,
    'Content-Type': 'application/json',
    'X-Tenant-Subdomain': domainService.subdomain,
  }
}

export async function HttpGet<TRequest, TResponse>({
  path,
  params,
}: HttpGetOptions<TRequest>): Promise<AxiosResponse<TResponse>> {
  return axios.get<TResponse>(path, {
    params,
    baseURL: apiRootUrl,
    headers: await getRequiredHeadersAsync(),
  })
}

export async function HttpPost<TRequest, TResponse>({
  path,
  body,
}: HttpPostOptions<TRequest>): Promise<AxiosResponse<TResponse>> {
  return axios.post<TRequest, AxiosResponse<TResponse>>(path, body, {
    baseURL: apiRootUrl,
    headers: await getRequiredHeadersAsync(),
  })
}

export async function HttpPut<TRequest, TResponse>({
  path,
  body,
}: HttpPutOptions<TRequest>): Promise<AxiosResponse<TResponse>> {
  return axios.put<TRequest, AxiosResponse<TResponse>>(path, body, {
    baseURL: apiRootUrl,
    headers: await getRequiredHeadersAsync(),
  })
}

export async function HttpDelete<TRequest, TResponse>({
  path,
  params,
}: HttpDeleteOptions<TRequest>): Promise<AxiosResponse<TResponse>> {
  return axios.delete<TResponse>(path, {
    params,
    baseURL: apiRootUrl,
    headers: await getRequiredHeadersAsync(),
  })
}
