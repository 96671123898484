import { createApiActions } from '@tabeeb/modules/shared/utils/actions'
import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_SESSIONS_PAGE_STATE')

// sessions
export const initiateSessionCreation = createAction('INITIATE_SESSION_CREATION')

export const openSession = createAction('OPEN_SESSION')

export const activateSession = createApiActions('ACTIVATE_SESSION')
export const addUsersToSession = createApiActions('ADD_USERS_TO_SESSION')
export const bulkCopySession = createApiActions('BULK_COPY_SESSION')
export const createSession = createApiActions('CREATE_SESSION')
export const deleteSession = createApiActions('DELETE_SESSION')
export const deleteSessionPermanently = createApiActions('DELETE_SESSION_PERMANENTLY')
export const leaveSession = createApiActions('LEAVE_SESSION')
export const publishSession = createApiActions('PUBLISH_SESSION')
export const restoreSession = createApiActions('RESTORE_SESSION')
export const switchSessionFolder = createApiActions('SWITCH_SESSION_FOLDER')
export const switchSessionOwner = createApiActions('SWITCH_SESSION_OWNER')
export const switchSessionReviewer = createApiActions('SWITCH_SESSION_REVIEWER')
export const switchSessionStorage = createApiActions('SWITCH_SESSION_STORAGE')
export const unpublishSession = createApiActions('UNPUBLISH_SESSION')
export const getSessions = createApiActions('GET_SESSIONS')

export const setAdminPermissionFlag = createAction('SET_ADMIN_PERMISSION_FLAG')

export const openTrash = createAction('OPEN_TRASH')
export const closeTrash = createAction('CLOSE_TRASH')

export const getTenantUsersCount = createApiActions('GET_TENANT_USERS_COUNT')

export const loadSessionsList = createAction('LOAD_SESSIONS_LIST')
export const setSessionsList = createAction('SET_SESSIONS_LIST')
export const setSessionListLoading = createAction('SET_SESSION_LIST_LOADING')

export const updateContentState = createAction('UPDATE_CONTENT_STATE')

// pagination
export const setPageNumber = createAction('SET_SESSIONS_LIST_PAGE_NUMBER')
export const setPageSize = createAction('SET_SESSIONS_LIST_PAGE_SIZE')

// storages
export const getTenantStorages = createApiActions('GET_TENANT_STORAGES')
export const setSelectedStorage = createAction('SET_SELECTED_STORAGE')

// folders
export const setCurrentFolder = createAction('SET_CURRENT_FOLDER')
export const setCurrentFolderId = createAction('SET_CURRENT_FOLDER_ID')
export const clearCurrentFolder = createAction('CLEAR_CURRENT_FOLDER')
export const openFolder = createAction('OPEN_FOLDER')

export const createFolder = createApiActions('CREATE_FOLDER')
export const deleteFolder = createApiActions('DELETE_FOLDER')
export const deleteFolderPermanently = createApiActions('DELETE_FOLDER_PERMANENTLY')
export const updateFolder = createApiActions('UPDATE_FOLDER')
export const leaveFolder = createApiActions('LEAVE_FOLDER')
export const restoreFolder = createApiActions('RESTORE_FOLDER')

export const getFolder = createApiActions('GET_FOLDER')
export const getFolders = createApiActions('GET_FOLDERS')
export const getDeletedFolders = createApiActions('GET_DELETED_FOLDERS')
export const getIsFolderParticipant = createApiActions('GET_IS_FOLDER_PARTICIPANT')

// folder users
export const addUsersToFolder = createApiActions('ADD_USERS_TO_FOLDER')
export const getUsersInFolder = createApiActions('GET_USERS_IN_FOLDER')
export const editUsersInFolder = createApiActions('EDIT_USERS_IN_FOLDER')

// users search
export const clearUsersSearchValue = createAction('CLEAR_USERS_SEARCH_VALUE')
export const updateUsersSearchValue = createAction('UPDATE_USERS_SEARCH_VALUE')

export const searchUsersRequest = createAction('SEARCH_USERS_REQUEST')
export const searchUsersFailed = createAction('SEARCH_USERS_FAILED')
export const searchUsersSuccess = createAction('SEARCH_USERS_SUCCESS')

export const clearFoundUsersList = createAction('CLEAR_FOUND_USERS_LIST')
export const setFoundUsersList = createAction('SET_FOUND_USERS_LIST')

// content sharing
export const searchTargetContentRequest = createAction('SEARCH_TARGET_CONTENT_REQUEST')
export const searchTargetContentSuccess = createAction('SEARCH_TARGET_CONTENT_SUCCESS')
export const searchTargetContentFailed = createAction('SEARCH_TARGET_CONTENT_FAILED')

export const setTargetContentSearchResult = createAction('SET_TARGET_CONTENT_SEARCH_RESULT')
export const resetTargetContentSearchResult = createAction('RESET_TARGET_CONTENT_SEARCH_RESULT')
export const setSelectedTargetContentForMerging = createAction('SET_SELECTED_TARGET_CONTENT_FOR_MERGING')

export const updateTargetContentSearchValue = createAction('UPDATE_TARGET_CONTENT_SEARCH_VALUE')

export const createContentSharing = createAction('CREATE_CONTENT_SHARING')
export const createContentSharingRequest = createAction('CREATE_CONTENT_SHARING_REQUEST')
export const createContentSharingSuccess = createAction('CREATE_CONTENT_SHARING_SUCCESS')
export const createContentSharingFailed = createAction('CREATE_CONTENT_SHARING_FAILED')

export const setTenantForSharing = createAction('SET_TENANT_FOR_SHARING')
export const setOwnerForSharing = createAction('SET_OWNER_FOR_SHARING')
export const removeOwner = createAction('REMOVE_OWNER')

export const resetShareSessionDialog = createAction('RESET_SHARE_SESSION_DIALOG')

// selection
export const setSelectedSessions = createAction('SET_SELECTED_SESSIONS')
export const addSessionsToSelection = createAction('ADD_SESSIONS_TO_SELECTION')
export const removeSessionsFromSelection = createAction('REMOVE_SESSIONS_FROM_SELECTION')

// layout
export const setPageLayout = createAction('SET_PAGE_LAYOUT')
export const setPageMode = createAction('SET_PAGE_MODE')

// dialogs
export const openSessionsPageDialog = createAction('OPEN_SESSIONS_PAGE_DIALOG')
export const closeSessionsPageDialog = createAction('CLOSE_SESSIONS_PAGE_DIALOG')

export const setActivateSessionDialog = createAction('SET_ACTIVATE_SESSION_DIALOG')
export const resetActivateSessionDialog = createAction('RESET_ACTIVATE_SESSION_DIALOG')

// selected sessions actions
export const updateProgressPopupState = createAction('UPDATE_PROGRESS_POPUP_STATE')
export const showPropgressPopup = createAction('SHOW_PROGRESS_POPUP')
export const hidePropgressPopup = createAction('HIDE_PROGRESS_POPUP')

export const addUsersToSelectedSessions = createAction('ADD_USERS_TO_SELECTED_SESSIONS')
export const createContentSharings = createAction('CREATE_CONTENT_SHARINGS')
export const restoreSelectedSessions = createAction('RESTORE_SELECTED_SESSIONS')
export const switchSelectedSessionsOwner = createAction('SWITCH_SELECTED_SESSIONS_OWNER')
export const switchSelectedSessionsReviewer = createAction('SWITCH_SELECTED_SESSIONS_REVIEWER')
export const switchSelectedSessionsStorage = createAction('SWITCH_SELECTED_SESSIONS_STORAGE')

// filters
export const setSearchText = createAction('SET_SESSIONS_SEARCH_TEXT')
export const updateDateFilter = createAction('UPDATE_DATE_FILTER')
export const updateReviewStatusFilter = createAction('UPDATE_REVIEW_STATUS_FILTER')
export const updateSessionStatusFilter = createAction('UPDATE_SESSION_STATUS_FILTER')
export const updateChatStatusFilter = createAction('UPDATE_CHAT_STATUS_FILTER')
export const resetFilters = createAction('RESET_SESSIONS_FILTERS')

// sorting
export const setSorting = createAction('SET_SESSIONS_SORTING')
export const setSortingColumn = createAction('SET_SESSIONS_SORTING_COLUMN')
export const setSortingOrder = createAction('SET_SESSIONS_SORTING_ORDER')

// configuration
export const setFilters = createAction('SET_SESSIONS_PAGE_FILTERS')
export const setFeatures = createAction('SET_SESSIONS_PAGE_FEATURES')
export const setSortings = createAction('SET_SESSIONS_PAGE_SORTINGS')
export const setSessionActions = createAction('SET_SESSION_ACTIONS')
export const setFolderActions = createAction('SET_FOLDER_ACTIONS')
