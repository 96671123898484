import { put, takeLatest, takeLeading, take, all, select, race, fork, cancel, call, join } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import routes from '@tabeeb/routes'
import getErrorMessageFromResponse from '@tabeeb/modules/shared/utils/getErrorMessageFromResponse'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'
import oidcUserManager from '@tabeeb/services/oidcUserManager'
import { persistor } from '@tabeeb/store'

import * as appActions from '@tabeeb/modules/appConfigState/actions'
import { getIsUserNeedsToAcceptChangesRequest } from '@tabeeb/modules/policies/actions'
import * as invitesActions from '@tabeeb/modules/invites/actions'
import {
  getBillingSettingsRequest,
  getSessionApprovalSettingsRequest,
} from '@tabeeb/modules/billingSettingsPage/actions'
import {
  getTenantPermissionsFailed,
  getTenantPermissionsRequest,
  getTenantPermissionsSuccess,
} from '@tabeeb/modules/permissions/actions'
import { closeNotificationsTab } from '@tabeeb/modules/notificationsPage/actions'
import * as sessionActions from '@tabeeb/state/actions/sessionActions'
import * as notificationActions from '@tabeeb/modules/notification/actions'

import * as accountActions from '../actions'
import * as accountSelectors from '../selectors'

import { TenantAuthErrorTypes } from '../constants'

import { getSetupMfaISUrl } from '../helper'
import { tryActivateProfile } from '../actions'

function* handleTenantAuthenticateError(errorCode) {
  switch (errorCode) {
    case TenantAuthErrorTypes.ForbiddenTenant:
      yield put(push(routes.switchTenant, { tenantAuthError: 'Access to the tenant has been restricted' }))
      return
    case TenantAuthErrorTypes.ProfileDisabled:
      yield put(tryActivateProfile.request())
      const result = yield take([tryActivateProfile.success, tryActivateProfile.failed])
      if (result.type === tryActivateProfile.success().type) {
        window.location.reload()
      } else if (result.type === tryActivateProfile.failed().type) {
        yield put(push(routes.switchTenant, { tenantAuthError: 'Your account has been disabled' }))
      }
      return
    case TenantAuthErrorTypes.TenantRequiresMfa:
      const url = yield call(getSetupMfaISUrl)
      window.location.replace(url)
      return
    case TenantAuthErrorTypes.AccountNotCreated:
      yield put(accountActions.setTenantAccountCreated(false))
      yield put(accountActions.tenantAuthFlowCompleted())
      return
    case TenantAuthErrorTypes.UnacceptableRole:
      yield put(accountActions.onLogout())
      return
    default:
      yield put(accountActions.setIsTenantAccountUnexpectedError(true))
      yield put(accountActions.tenantAuthFlowCompleted())
  }
}

function* handleTenantAccountErrorWithLock({ payload: errorCode }) {
  const lock = yield select(accountSelectors.getIsTenantAccountLocked)

  if (!lock) {
    yield put(accountActions.resetTenantAuthenticate())
    const handleErrorTask = yield fork(handleTenantAuthenticateError, errorCode)
    yield join(handleErrorTask)
  }
}

function* onTenantAuthenticate() {
  yield put(accountActions.getMeRequest())

  const [, failed] = yield race([take(accountActions.getMeSuccess), take(accountActions.getMeFailed)])

  if (failed) {
    const handleErrorTask = yield fork(handleTenantAuthenticateError, failed.response.data?.Error?.Code ?? '')
    yield join(handleErrorTask)
  } else {
    yield put(getTenantPermissionsRequest())
    yield put(getBillingSettingsRequest())
    yield put(getSessionApprovalSettingsRequest())
    yield put(getIsUserNeedsToAcceptChangesRequest())
    yield put(appActions.getVideoBridgeConfig.request())

    yield race({
      success: take(getTenantPermissionsSuccess),
      failed: take(getTenantPermissionsFailed),
    })

    yield put(accountActions.lockTenantAccount(false))
    yield put(accountActions.tenantAuthFlowCompleted())
  }
}

function* handleTenantAuthenticate() {
  const onAuthTask = yield fork(onTenantAuthenticate)

  const taskRetried = yield take(accountActions.tenantAuthenticateRetry)
  if (taskRetried) {
    yield cancel(onAuthTask)
    yield put(accountActions.resetTenantAuthenticate())
    yield put(accountActions.tenantAuthenticate())
  }
}

function* onLogout() {
  yield put(accountActions.resetTenantAuthenticate())
  yield put(sessionActions.resetSessionState())
  persistor.purge().then(() => {
    localStorage.removeItem('persist:root')
    oidcUserManager.clearStaleState().then(() => {
      oidcUserManager.signoutRedirect()
    })
  })
}

function* acceptInviteSuccess(action) {
  const acceptedInvite = action.response.data
  const currentUser = yield select(accountSelectors.getMe)
  yield put(closeNotificationsTab())
  const acceptedInviteEmail =
    acceptedInvite.RecipientEmail !== null ? acceptedInvite.RecipientEmail : acceptedInvite.Recipient.Email
  if (acceptedInviteEmail === currentUser.Email) {
    yield put(push(`${routes.session}?sessionId=${acceptedInvite.ContentId}`))
  } else {
    yield put(push(routes.home))
  }
}

function* acceptInviteFailed(action) {
  const isAuthorized = yield select(accountSelectors.getIsUserAuthorized)
  if (isAuthorized) {
    yield put(push(routes.home))
    yield put(notificationActions.onAddErrorNotification({ message: 'Invitation is not found' }))
  } else {
    yield put(push(routes.login))
  }
}

function* loginViaInviteFailed(action) {
  if (action.status === 403) {
    yield fork(onTenantAuthenticate)
  } else {
    const errorMessage = getErrorMessageFromResponse(action.response?.data)

    yield put(notificationActions.onAddErrorNotification({ message: errorMessage || 'Failed to login via invite' }))
    yield put(push(routes.login))
  }
}

function* loginViaInviteSuccess(action) {
  const sessionId = action.response.data

  yield put(sessionActions.onSetsessionSate())

  yield put(push(`${routes.session}?sessionId=${sessionId}`))
}

function* handleCreateProfileSuccess() {
  yield put(accountActions.tenantAuthenticateRetry())
}

function* handleCreateProfileFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Profile creation failed' }))
}

function* loginSaga() {
  yield all([
    takeLatest([signalrEvents[signalrConstants.tabeebHubName].onAccountDeleted, accountActions.onLogout], onLogout),
    takeLatest(invitesActions.acceptInvite.success, acceptInviteSuccess),
    takeLatest(invitesActions.acceptInvite.failed, acceptInviteFailed),
    takeLatest(accountActions.loginViaInvite.success, loginViaInviteSuccess),
    takeLatest(accountActions.loginViaInvite.failed, loginViaInviteFailed),
    takeLatest(accountActions.tenantAuthenticate, handleTenantAuthenticate),
    takeLatest(accountActions.createProfile.success, handleCreateProfileSuccess),
    takeLatest(accountActions.createProfile.failed, handleCreateProfileFailed),
    takeLeading(accountActions.onTenantAccountError, handleTenantAccountErrorWithLock),
  ])
}

export default loginSaga
