/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DisableUserNotificationRequest {
  /**
   *
   *
   * 100 = InviteToContent
   *
   * 200 = ContentActivity
   *
   * 300 = ChatMessage
   *
   * 400 = StartCall
   *
   * 500 = YouNewOwner
   *
   * 600 = YouOldOwner
   *
   * 700 = ApprovalResult
   *
   * 800 = ShareContent
   *
   * 810 = MergeContent
   *
   * 900 = AlertOwner
   *
   * 1000 = ReviewContent
   *
   * 1010 = ContentReviewResumed
   *
   * 1020 = InContentReview
   *
   * 1100 = YouNewReviewer
   *
   * 1200 = YouOldReviewer
   *
   * 1300 = ReviewCompleted
   *
   * 1310 = ReviewFeedback
   *
   * 1320 = AnswerApproved
   *
   * 1330 = AnswerRejected
   *
   * 1400 = AddUserToContent
   *
   * 1500 = AnswerQuestionReminder
   *
   * 1600 = AddUsersToFolder
   *
   * 1800 = LinkContentsApprovalResult
   *
   * 1900 = UnreadTrainingMaterialReminder
   *
   * 1910 = TrainingMaterialAssigned
   *
   * 2000 = MyCertificateExpiration
   *
   * 2005 = UserCertificateExpiration
   *
   * 2010 = QualificationExpiration
   *
   * 2020 = MyUserQualificationExpiration
   *
   * 2025 = UserQualificationExpiration
   *
   * 2030 = ContractExpiration
   */
  notificationType: NotificationTypeEnum
  tenantScoped: boolean
}

export interface EnableUserNotificationRequest {
  /**
   *
   *
   * 100 = InviteToContent
   *
   * 200 = ContentActivity
   *
   * 300 = ChatMessage
   *
   * 400 = StartCall
   *
   * 500 = YouNewOwner
   *
   * 600 = YouOldOwner
   *
   * 700 = ApprovalResult
   *
   * 800 = ShareContent
   *
   * 810 = MergeContent
   *
   * 900 = AlertOwner
   *
   * 1000 = ReviewContent
   *
   * 1010 = ContentReviewResumed
   *
   * 1020 = InContentReview
   *
   * 1100 = YouNewReviewer
   *
   * 1200 = YouOldReviewer
   *
   * 1300 = ReviewCompleted
   *
   * 1310 = ReviewFeedback
   *
   * 1320 = AnswerApproved
   *
   * 1330 = AnswerRejected
   *
   * 1400 = AddUserToContent
   *
   * 1500 = AnswerQuestionReminder
   *
   * 1600 = AddUsersToFolder
   *
   * 1800 = LinkContentsApprovalResult
   *
   * 1900 = UnreadTrainingMaterialReminder
   *
   * 1910 = TrainingMaterialAssigned
   *
   * 2000 = MyCertificateExpiration
   *
   * 2005 = UserCertificateExpiration
   *
   * 2010 = QualificationExpiration
   *
   * 2020 = MyUserQualificationExpiration
   *
   * 2025 = UserQualificationExpiration
   *
   * 2030 = ContractExpiration
   */
  notificationType: NotificationTypeEnum
  tenantScoped: boolean
}

export interface NotificationSetting {
  name: string
  description?: string | null
  /**
   *
   *
   * 100 = InviteToContent
   *
   * 200 = ContentActivity
   *
   * 300 = ChatMessage
   *
   * 400 = StartCall
   *
   * 500 = YouNewOwner
   *
   * 600 = YouOldOwner
   *
   * 700 = ApprovalResult
   *
   * 800 = ShareContent
   *
   * 810 = MergeContent
   *
   * 900 = AlertOwner
   *
   * 1000 = ReviewContent
   *
   * 1010 = ContentReviewResumed
   *
   * 1020 = InContentReview
   *
   * 1100 = YouNewReviewer
   *
   * 1200 = YouOldReviewer
   *
   * 1300 = ReviewCompleted
   *
   * 1310 = ReviewFeedback
   *
   * 1320 = AnswerApproved
   *
   * 1330 = AnswerRejected
   *
   * 1400 = AddUserToContent
   *
   * 1500 = AnswerQuestionReminder
   *
   * 1600 = AddUsersToFolder
   *
   * 1800 = LinkContentsApprovalResult
   *
   * 1900 = UnreadTrainingMaterialReminder
   *
   * 1910 = TrainingMaterialAssigned
   *
   * 2000 = MyCertificateExpiration
   *
   * 2005 = UserCertificateExpiration
   *
   * 2010 = QualificationExpiration
   *
   * 2020 = MyUserQualificationExpiration
   *
   * 2025 = UserQualificationExpiration
   *
   * 2030 = ContractExpiration
   */
  type: NotificationTypeEnum
  isEnabled: boolean
}

/**
 *
 *
 * 100 = InviteToContent
 *
 * 200 = ContentActivity
 *
 * 300 = ChatMessage
 *
 * 400 = StartCall
 *
 * 500 = YouNewOwner
 *
 * 600 = YouOldOwner
 *
 * 700 = ApprovalResult
 *
 * 800 = ShareContent
 *
 * 810 = MergeContent
 *
 * 900 = AlertOwner
 *
 * 1000 = ReviewContent
 *
 * 1010 = ContentReviewResumed
 *
 * 1020 = InContentReview
 *
 * 1100 = YouNewReviewer
 *
 * 1200 = YouOldReviewer
 *
 * 1300 = ReviewCompleted
 *
 * 1310 = ReviewFeedback
 *
 * 1320 = AnswerApproved
 *
 * 1330 = AnswerRejected
 *
 * 1400 = AddUserToContent
 *
 * 1500 = AnswerQuestionReminder
 *
 * 1600 = AddUsersToFolder
 *
 * 1800 = LinkContentsApprovalResult
 *
 * 1900 = UnreadTrainingMaterialReminder
 *
 * 1910 = TrainingMaterialAssigned
 *
 * 2000 = MyCertificateExpiration
 *
 * 2005 = UserCertificateExpiration
 *
 * 2010 = QualificationExpiration
 *
 * 2020 = MyUserQualificationExpiration
 *
 * 2025 = UserQualificationExpiration
 *
 * 2030 = ContractExpiration
 * @format int32
 */
export enum NotificationTypeEnum {
  InviteToContent = 100,
  ContentActivity = 200,
  ChatMessage = 300,
  StartCall = 400,
  YouNewOwner = 500,
  YouOldOwner = 600,
  ApprovalResult = 700,
  ShareContent = 800,
  MergeContent = 810,
  AlertOwner = 900,
  ReviewContent = 1000,
  ContentReviewResumed = 1010,
  InContentReview = 1020,
  YouNewReviewer = 1100,
  YouOldReviewer = 1200,
  ReviewCompleted = 1300,
  ReviewFeedback = 1310,
  AnswerApproved = 1320,
  AnswerRejected = 1330,
  AddUserToContent = 1400,
  AnswerQuestionReminder = 1500,
  AddUsersToFolder = 1600,
  LinkContentsApprovalResult = 1800,
  UnreadTrainingMaterialReminder = 1900,
  TrainingMaterialAssigned = 1910,
  MyCertificateExpiration = 2000,
  UserCertificateExpiration = 2005,
  QualificationExpiration = 2010,
  MyUserQualificationExpiration = 2020,
  UserQualificationExpiration = 2025,
  ContractExpiration = 2030,
}

export interface NotificationsCategory {
  name: string
  notificationSettings: NotificationSetting[]
}

export interface UserNotificationSettingResponse {
  isEnabled: boolean
}

export interface UserNotificationSettingsResponse {
  notificationCategories: NotificationsCategory[]
}
