import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'

import Routes from '@tabeeb/routes'
import { getLogoUrl } from '@tabeeb/modules/appConfigState/selectors'

import styles from './styles'

const Logo = ({ classes }) => {
  const [loaded, setLoaded] = useState(false)
  const url = useSelector(getLogoUrl)

  const handleFailed = () => {
    setLoaded(false)
    window.dispatchEvent(new CustomEvent('resize'))
  }

  const handleLoad = () => {
    setLoaded(true)
    window.dispatchEvent(new CustomEvent('resize'))
  }

  return (
    <Link to={Routes.home}>
      <img
        className={classes.logo}
        style={{
          opacity: loaded ? 1 : 0,
        }}
        onLoad={handleLoad}
        onError={handleFailed}
        src={url}
        alt='logo'
      />
    </Link>
  )
}

Logo.propTypes = {
  classes: PropTypes.shape({
    logo: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(Logo)
