import { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Box, Tab, Menu, MenuItem, withStyles } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import styles from './styles'

const NavbarTab = ({ classes, item, color, backgroundColor, display, handleClick }) => {
  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'))
  }, [display])

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(undefined)

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget)
    setIsMenuOpen(true)
  }

  const handleClose = () => {
    setIsMenuOpen(false)
  }

  const tabProps = {
    className: classes.tab,
    style: {
      color,
      display: display ? 'block' : 'none',
    },
  }

  if (item.subitems) {
    return (
      <>
        <Tab
          {...tabProps}
          onClick={handleOpen}
          label={
            <Box className={classes.label}>
              {item.label}
              <ArrowDropDownIcon />
            </Box>
          }
        />
        <Menu
          PaperProps={{ style: { background: backgroundColor } }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleClose}
        >
          {item.subitems.map((subitem) => (
            <Link
              key={subitem.label}
              onClick={handleClick}
              className={classes.link}
              style={{ color }}
              to={subitem.to}
              title={subitem.title}
            >
              <MenuItem className={classes.menuItem} onClick={handleClose} disabled={subitem.disabled}>
                {subitem.label}
              </MenuItem>
            </Link>
          ))}
        </Menu>
      </>
    )
  }
  if (item.childComponent) {
    const ChildComponent = item.childComponent
    return (
      <>
        <Tab
          {...tabProps}
          onClick={handleOpen}
          label={
            <Box className={classes.label}>
              {item.label}
              <ArrowDropDownIcon />
            </Box>
          }
        />
        <Menu
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleClose}
        >
          <ChildComponent handleClose={handleClose} />
        </Menu>
      </>
    )
  }
  return (
    <Link className={classes.link} to={item.to} value={item.to} onClick={handleClick}>
      <Tab
        {...tabProps}
        label={
          <Box className={classes.label}>
            {item.label}
            {item.icon}
          </Box>
        }
      />
    </Link>
  )
}

NavbarTab.defaultProps = {
  display: true,
}

NavbarTab.propTypes = {
  classes: PropTypes.shape({
    tab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    menuItem: PropTypes.string.isRequired,
  }).isRequired,
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
    to: PropTypes.string,
    childComponent: PropTypes.elementType,
    subitems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
      })
    ),
    icon: PropTypes.element,
  }).isRequired,
  display: PropTypes.bool,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  handleClick: PropTypes.func,
}

function mapStateToProps(state, { selected }) {
  return {
    color: selected
      ? appConfigStateSelectors.getMainSelectedTextColor(state)
      : appConfigStateSelectors.getMainTextColor(state),
    backgroundColor: appConfigStateSelectors.getMainBannerColor(state),
  }
}

export default memo(withStyles(styles)(connect(mapStateToProps)(NavbarTab)))
