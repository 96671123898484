import { inputBaseClasses, outlinedInputClasses, Select, selectClasses, styled } from '@mui/material'

export const StyledSelect = styled(Select)(({ theme }) => {
  return {
    background: 'red',

    [`&.${inputBaseClasses.root}`]: {
      backgroundColor: '#fff',
      border: '1px solid #e5e5e5',
      borderRadius: theme.shape.borderRadius,
      flexGrow: 1,
      paddingInline: theme.spacing(2),
      paddingBlock: theme.spacing(0.75),
      height: 44.34,

      ...theme.typography.body1,
      fontWeight: 500,
    },

    [`& .${inputBaseClasses.input}`]: {
      padding: 0,
    },

    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: 'none',
    },

    [`& .${selectClasses.icon}`]: {
      marginRight: theme.spacing(1.25),
    },
  }
})
