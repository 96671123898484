import { createSelector } from 'reselect'

import { xor } from 'lodash'

import { ReviewStatus, SessionStatus } from '@tabeeb/enums'
import { getIsChatEnabled, getIsUnreadSessionChatsIndicatorEnabled } from '@tabeeb/modules/appConfigState/selectors'

import { ChatStatus, Filter } from '../enums'

export const getEnabledFilters = (state) => state.sessionsPage.configuration.filters
export const getIsFilterEnabled = createSelector([getEnabledFilters, (_, filter) => filter], (filters, filter) => {
  return filters.includes(filter)
})

export const getIsFiltrationByChatStatusEnabled = (state) =>
  getIsChatEnabled(state) &&
  getIsUnreadSessionChatsIndicatorEnabled(state) &&
  getIsFilterEnabled(state, Filter.ByChatStatus)
export const getIsFiltrationByDateEnabled = (state) => getIsFilterEnabled(state, Filter.ByDate)
export const getIsFiltrationByReviewStatusEnabled = (state) => getIsFilterEnabled(state, Filter.ByReviewStatus)
export const getIsFiltrationBySessionStatusEnabled = (state) => getIsFilterEnabled(state, Filter.BySessionStatus)

export const getFiltrationState = (state) => state.sessionsPage.filtration

export const getDateFilter = (state) => getFiltrationState(state).date
export const getReviewStatusFilter = (state) => getFiltrationState(state).reviewStatus
export const getSearchQuery = (state) => getFiltrationState(state).searchText
export const getSessionStatusFilter = (state) => getFiltrationState(state).sessionStatus
export const getChatStatusFilter = (state) => getFiltrationState(state).chatStatus

export const getIsFiltrationByChatStatusActive = createSelector(
  [getIsFiltrationByChatStatusEnabled, getChatStatusFilter],
  (enabled, filter) => enabled && filter !== ChatStatus.All
)

export const getIsFiltrationByReviewStatusActive = createSelector(
  [getIsFiltrationByReviewStatusEnabled, getReviewStatusFilter],
  (enabled, filter) => enabled && xor(Object.values(ReviewStatus), filter).length > 0
)

export const getIsFiltrationBySessionStatusActive = createSelector(
  [getIsFiltrationBySessionStatusEnabled, getSessionStatusFilter],
  (enabled, filter) => enabled && xor(Object.values(SessionStatus), filter).length > 0
)

export const getIsFiltrationActive = createSelector(
  [
    getIsFiltrationByDateEnabled,
    getIsFiltrationByChatStatusActive,
    getIsFiltrationByReviewStatusActive,
    getIsFiltrationBySessionStatusActive,
  ],
  (...filters) => filters.some(Boolean)
)
