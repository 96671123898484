import { useQuery } from '@tanstack/react-query'

import { getUnreadUserChatsCountRequest } from '../requests'

const queryKey = 'unread-chats-count'

const useUnreadChatsCount = (enabled: boolean = true) => {
  const query = useQuery({
    queryKey: [queryKey],
    queryFn: getUnreadUserChatsCountRequest,
    enabled,
  })

  return { count: query.data || 0, invalidate: query.refetch }
}

export default useUnreadChatsCount
